import React from "react";
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters } from "../../actions/AppActions";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle, faTh, faCompressAlt, faCompress, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import Tabs from "@material-ui/core/Tabs";
import { confirmAlert } from 'react-confirm-alert';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BASE_URL, ADMIN, ENVIRONMENT } from "../../StringConstants";
//import * as api from "../../services/API.js";
import * as api from '../../services/apiAction'
import Logo from "../../Images/Connecting.PNG";


import i18n from "i18next";
import Connecting from "../../Connecting";

class AppStore extends React.Component {
  state = {
    filterArray: [],
    selectedApps: [],
    selectedAppsAdmin: [],
    remainingApps: [],
    showModal: false,
    showDashBoard: false,
    activeTab: null,
    showConfigure: false,
    appUrl: "",
    isIframe: true,
    maximizedAppStore: false,
    hideApply: false,
    hideCancel: false,
    isSisense: false,
    isPowerBi: false,
    PowerBiReportId: "",
    showLoader: false,
    showDashModal: false,
    opensInNewTab: false,
    isPowerBiWithoutToken: false

  }


  componentWillMount() {
    let clients = [...this.props.app.filterObject.tenant];
    let clientsFilterObj;
    if (clients.length > 0) {
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: clients.map((a) => a.toUpperCase()),
      }
    } else {
      clientsFilterObj = {
        all: true,
      }
    }
    this.setState({
      filterArray: [
        {

          levels: [
            {
              dim: "[Client.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Pseudo"],
              },
            },
            {
              dim: "[Client.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
    });
  }
  handleNewTab = (url) => {

    let win = window.open(url, '_blank', 'top=50,left=250,location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no,width=800,height=500');
    win.focus();
  }
  showConfirmAlert = (url) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <div className="card-appstore">

              <h4>{i18n.t("This app opens in new window")}</h4>
              <div >{i18n.t("Do you want to continue ?")}</div>
              <div className="card-appstore-btns" style={{ position: 'relative', left: '70%' }}>
                <button type="button" className="btn-remove" style={{ width: '4rem', marginRight: '5px' }} onClick={() => {
                  this.handleNewTab(url);
                  onClose();
                }}>{i18n.t("Ok")}</button>
                <button type="button" className="btn-remove" style={{ width: '4rem' }} onClick={onClose}>{i18n.t("Cancel")}</button>

              </div>
            </div>
          </div>
        );
      }
    });
  }
  componentDidMount() {
    if (this.props.isConnected) {
      this.loadApps(this.props);
    }
  }
  loadApps = (props) => {

    let client = props.app.filterObject.tenant.length == 1 ? props.app.filterObject.tenant[0] : null;
    let clientId = props.app.filterObject.tenantId.length == 1 ? props.app.filterObject.tenantId[0] : 0;
    api.get(`activeappstores/` + client + '/' + clientId + '/' + props.userDetails.id).then((res) => {
      this.setState({ remainingApps: res })
    });

    api.get(`appstoreconfigs/` + client + '/' + clientId + '/' + props.userDetails.id).then((result) => {
      if (result.length > 0) {
        if (!result[0].isIframe) {
          this.setState({ activeTab: result[0].appId, opensInNewTab: true, isIframe: false, isSisense: false, isPowerBi: false, isPowerBiWithoutToken: false });
          this.showConfirmAlert(result[0].appURL);

        } else {
          let isSisense = false;
          let isPowerBi = false;
          isSisense = result[0].visualizationType == "sisense";
          isPowerBi = result[0].visualizationType == "powerbi"; //change_report

          if (isSisense && this.props.userDetails.isSisenseEnabled) {
            if (ENVIRONMENT !== 'local') {
              this.loadDash('sisenseAppStore', result[0].appURL);
            }
            this.setState({ activeTab: result[0].appId, isIframe: false, appUrl: result[0].appURL.replaceAll("amp;", ""), isSisense, isPowerBi: false, isPowerBiWithoutToken: false, opensInNewTab: false });
          } else if (isPowerBi && this.props.userDetails.isPowerBIEnabled) {
            let isPowerBiService = result[0].appURL.includes('app.powerbi.com');
            if (isPowerBiService) {
              let PowerBiReportId = result[0].appURL.substring(
                result[0].appURL.lastIndexOf("reportId") + 9,
                result[0].appURL.lastIndexOf("reportId") + 45)
              fetch(`https://api.powerbi.com/v1.0/myorg/reports/${PowerBiReportId}`, {
                method: "GET",
                headers: { "Authorization": "Bearer " + localStorage.getItem("access_token_powerbi"), }
              }).then(response => response.json())
                .then(
                  (res) => {
                    this.setState({ activeTab: result[0].appId, isIframe: false, appUrl: res.embedUrl, isPowerBi, isPowerBiWithoutToken: false, isSisense: false, PowerBiReportId, opensInNewTab: false });
                  },
                  (error) => {
                    console.log("PowerBi fetcherror", error);
                  }
                )
            } else {
              this.setState({ activeTab: result[0].appId, isIframe: false, appUrl: result[0].appURL, isPowerBi, isPowerBiWithoutToken: true, isSisense: false, opensInNewTab: false });

            }

          } else {
            this.setState({ activeTab: result[0].appId, isIframe: result[0].isIframe, isSisense: false, isPowerBi: false, isPowerBiWithoutToken: false, appUrl: result[0].appURL.replaceAll("amp;", ""), opensInNewTab: false });
          }
        }

        this.setState({ showDashBoard: true, showConfigure: false, showModal: false, selectedApps: result });
        props.callUserEnagementSessionAPI('SynOps Apps', 0, result[0].appId, true)

      } else {
        api.get(`appstoreconfigs/` + client + '/' + clientId + '/' + props.userDetails.id).then((result) => {
          this.setState({ showModal: true, showDashBoard: false, showConfigure: true, selectedAppsAdmin: result });
        });
        props.callUserEnagementSessionAPI('SynOps Apps', 0, 0, true)

      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant && nextProps.isConnected) {
      let filterArray = [];
      if (nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length == 0) {
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "title": "Client",
                "dim": "[Client.Client]",
                "datatype": "text",
                "filter": {
                  "all": true
                }
              }
            ],
          }
        ]

      } else if (nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length != 0) {

        let tenants = [];
        this.props.userDetails.tenant.map((item) => {
          tenants.push(item.pseudoName);
        });
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Client.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": tenants.map(a => a.toUpperCase()) },
              },
            ],
          }
        ]
      } else {

        filterArray = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Client.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": nextProps.app.filterObject.tenant.map(a => a.toUpperCase()) },
              },
            ],
          }
        ]
      }

      this.loadApps(nextProps);
      this.setState({ filterArray }, () => {
        if (this.state.isSisense && ENVIRONMENT != 'local' && nextProps.userDetails.isSisenseEnabled) {
          this.loadDash('sisenseAppStore', this.state.appUrl);
        }
      });
    }
    if (nextProps.isConnected !== this.props.isConnected) {
      if (nextProps.isConnected && ENVIRONMENT !== 'local' && this.props.userDetails.isSisenseEnabled) {
        this.loadApps(nextProps);
      }
    }

  }
  clearSelected = () => {
    if ((this.props.userDetails.featureIds &&
      this.props.userDetails.featureIds.some(
        (val) => val == 28
      )) || this.props.userDetails.dashboardRole == ADMIN) {
      this.props.setActiveScreen('T2');
    }
  }
  startPersonalizing = () => {
    this.setState({ showModal: false });
  }

  addToCurrent = (app) => {
    let remainingApps = [...this.state.remainingApps];
    let selectedAppsAdmin = [...this.state.selectedAppsAdmin]
    if (remainingApps.length > 0) {
      this.setState({
        hideCancel: false,
        hideApply: false
      })
    }
    let newList = [];
    remainingApps.map((ele) => {
      if (app.appId != ele.appId) {
        newList.push(ele);
      } else {
        ele.isEnabled = true;
        selectedAppsAdmin.push(ele);
      }
    })
    this.setState({ remainingApps: newList, selectedAppsAdmin });
  }
  removeApp = (app) => {
    let remainingApps = [...this.state.remainingApps];
    let selectedAppsAdmin = [...this.state.selectedAppsAdmin]
    let newList = [];
    selectedAppsAdmin.map((ele) => {
      if (app.appId == ele.appId) {
        if (remainingApps.findIndex(x => x.appId == app.appId) == -1) {
          remainingApps.push(ele);
          let rm = { ...ele };
          rm.isEnabled = false;
          newList.push(rm);
        }
      } else {
        newList.push(ele);
      }
    })
    this.setState({ remainingApps, selectedAppsAdmin: newList });
  }
  applySelected = () => {
    let configData = [];
    this.state.selectedAppsAdmin.map((item) => {
      configData.push({ id: item.id, appId: item.appId, isEnabled: ("isEnabled" in item) ? item.isEnabled : true, appURL: item.appURL.replaceAll("amp;", ""), isIframe: item.isIframe, createdBy: this.props.userDetails.userId, modifiedBy: this.props.userDetails.userId, userId: this.props.userDetails.id });
    })
    api.post(`appstoreconfigs`, configData).then((res) => {
      let client = this.props.app.filterObject.tenant.length == 1 ? this.props.app.filterObject.tenant[0] : null;
      let clientId = this.props.app.filterObject.tenantId.length == 1 ? this.props.app.filterObject.tenantId[0] : 0;
      api.get(`appstoreconfigs/` + client + '/' + clientId + '/' + this.props.userDetails.id).then((result) => {
        if (result.length > 0) {
          if (!result[0].isIframe) {
            this.setState({ activeTab: result[0].appId, opensInNewTab: true, isIframe: false, isSisense: false, isPowerBi: false, isPowerBiWithoutToken: false })
            this.showConfirmAlert(result[0].appURL);

          } else {
            let isSisense = false;
            let isPowerBi = false;
            isSisense = result[0].visualizationType == "sisense";
            isPowerBi = result[0].visualizationType == "powerbi"; //change_report

            if (isSisense && this.props.userDetails.isSisenseEnabled) {
              if (ENVIRONMENT !== 'local') {
                this.loadDash('sisenseAppStore', result[0].appURL);
              }
              this.setState({ activeTab: result[0].appId, isIframe: false, appUrl: result[0].appURL.replaceAll("amp;", ""), isSisense, isPowerBi: false, isPowerBiWithoutToken: false, opensInNewTab: false });
            } else if (isPowerBi && this.props.userDetails.isPowerBIEnabled) {
              let isPowerBiService = result[0].appURL.includes('app.powerbi.com');
              if (isPowerBiService) {
                let PowerBiReportId = result[0].appURL.substring(
                  result[0].appURL.lastIndexOf("reportId") + 9,
                  result[0].appURL.lastIndexOf("reportId") + 45)
                fetch(`https://api.powerbi.com/v1.0/myorg/reports/${PowerBiReportId}`, {
                  method: "GET",
                  headers: { "Authorization": "Bearer " + localStorage.getItem("access_token_powerbi"), }
                }).then(response => response.json())
                  .then(
                    (res) => {
                      this.setState({ activeTab: result[0].appId, isIframe: false, appUrl: res.embedUrl, isPowerBi, isPowerBiWithoutToken: false, isSisense: false, PowerBiReportId, opensInNewTab: false });
                    },
                    (error) => {
                      console.log("PowerBi fetcherror", error);
                    }
                  )
              } else {
                this.setState({ activeTab: result[0].appId, isIframe: false, appUrl: result[0].appURL, isPowerBi, isPowerBiWithoutToken: true, isSisense: false, opensInNewTab: false });
              }
            } else {
              this.setState({ activeTab: result[0].appId, isIframe: result[0].isIframe, isSisense: false, isPowerBi: false, isPowerBiWithoutToken: false, appUrl: result[0].appURL.replaceAll("amp;", ""), opensInNewTab: false });
            }
          }

          this.setState({ showDashBoard: true, showConfigure: false, showModal: false, selectedApps: result });
          this.props.callUserEnagementSessionAPI('SynOps Apps', 0, result[0].appId, true)

        } else {
          this.setState({ showModal: true, showDashBoard: false, showConfigure: true, selectedApps: result, hideApply: true, hideCancel: true });
          this.props.callUserEnagementSessionAPI('SynOps Apps', 0, 0, true)

        }
      });
    });
  }
  setTab = (app) => {
    this.setState({ isPowerBi: false, isPowerBiWithoutToken: false });
    if (!app.isIframe) {
      this.setState({ activeTab: app.appId, opensInNewTab: true, isIframe: false, isSisense: false, isPowerBi: false, isPowerBiWithoutToken: false })
      this.showConfirmAlert(app.appURL);

    } else {

      this.props.callUserEnagementSessionAPI('SynOps Apps', 0, app.appId, true)
      let isSisense = false;
      let isPowerBi = false;
      isSisense = app.visualizationType == "sisense";
      isPowerBi = app.visualizationType == "powerbi"; //change_report

      if (isSisense && this.props.userDetails.isSisenseEnabled) {
        if (ENVIRONMENT !== 'local') {
          this.loadDash('sisenseAppStore', app.appURL);
        }
        this.setState({ activeTab: app.appId, isIframe: false, appUrl: app.appURL.replaceAll("amp;", ""), isSisense, isPowerBi: false, isPowerBiWithoutToken: false, opensInNewTab: false });
      } else if (isPowerBi && this.props.userDetails.isPowerBIEnabled) {
        let isPowerBiService = app.appURL.includes('app.powerbi.com');
        if (isPowerBiService) {
          let PowerBiReportId = app.appURL.substring(
            app.appURL.lastIndexOf("reportId") + 9,
            app.appURL.lastIndexOf("reportId") + 45)
          fetch(`https://api.powerbi.com/v1.0/myorg/reports/${PowerBiReportId}`, {
            method: "GET",
            headers: { "Authorization": "Bearer " + localStorage.getItem("access_token_powerbi"), }
          }).then(response => response.json())
            .then(
              (result) => {
                this.setState({ activeTab: app.appId, isIframe: false, appUrl: result.embedUrl, isPowerBi, isPowerBiWithoutToken: false, isSisense: false, PowerBiReportId, opensInNewTab: false });
              },
              (error) => {
                console.log("PowerBi fetcherror", error);
              }
            )
        } else {
          this.setState({ activeTab: app.appId, isIframe: false, appUrl: app.appURL, isPowerBi, isPowerBiWithoutToken: true, isSisense: false, opensInNewTab: false });
        }
      } else {
        this.setState({ activeTab: app.appId, isIframe: app.isIframe, isSisense: false, isPowerBi: false, isPowerBiWithoutToken: false, appUrl: app.appURL.replaceAll("amp;", ""), opensInNewTab: false });
      }

    }
  }
  //------------------------------------------
  applyDbFilters = (db, dashId) => {
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false,
    };

    let filters = [...this.state.filterArray];

    db.$$model.filters.update(filters[0], filterOptions);

  };

  loadDash = (divId, url) => {
    if (divId != 'drillDash') {
      //Get dashbord IDs
      this.setState({ showLoader: true });
      if (url) {
        let dashboardId = url.split("/");
        let dashId = dashboardId[dashboardId.length - 1];
        this.props.currentApp.dashboards
          .load(dashboardId[dashboardId.length - 1])
          .then((dash) => this.afterLoadDash(dash, divId, dashId));
      }
    } else {
      //detail dashboard(widget modal)
      this.props.currentApp.dashboards
        .load(url)
        .then((dash) => this.afterLoadDash(dash, divId, url));
    }

  };


  afterLoadDash = (db, divId, dashId) => {

    this.applyDbFilters(db, dashId);
    let widgets = [];
    const layout = db.$$model.layout.columns[0].cells;
    const wRoot = db.$$model.title.startsWith("Drill") ? document.getElementById('dbModalAppStore') : document.getElementById(divId)
    if (wRoot) wRoot.innerHTML = "";
    let i = 0;
    layout.forEach(col => {
      i++;
      const row = this.createRow(i);
      col.subcells.forEach(cell => {
        const width = cell.width ? cell.width : 100;
        row.appendChild(this.createLayout(i, cell.elements[0], width, dashId));
        widgets.push(cell.elements[0].widgetid);
      })
      if (wRoot) {
        wRoot.appendChild(row);
      }
      if (i === layout.length) setTimeout(() => this.loadWgt(db, widgets, divId), 1000);
    });

  }

  createRow = (i) => {
    const row = document.createElement('div');
    row.className = "row";
    row.style.marginLeft = "0px";
    row.style.marginRight = "0px";
    row.id = "row" + i;
    return row;
  }

  createLayout = (i, el, width, dashId) => {
    const headerDiv = document.createElement("div");
    headerDiv.id = "head" + el.widgetid;
    headerDiv.style.padding = "5px 20px";
    headerDiv.style.color = "white";
    headerDiv.style.borderTopRightRadius = '10px';
    headerDiv.style.borderTopLeftRadius = '10px';
    // if (dashId == '606c97d5ecbae9002d951b71' || dashId == '5f7c12fd3965112230dbf2cd' || dashId == '5f702a953965112230dbd29d' || dashId == '5f92c8f85fd2f53f38600333') {
    // } else {
    headerDiv.style.backgroundColor = "#0242C6";
    // }

    const height = el.height === "" || el.height === 512 ? "100%" : el.height.toString().replace("px", "") + "px";
    const div = document.createElement('div');
    div.id = el.widgetid;
    div.style.height = height;
    // if (dashId == '606c97d5ecbae9002d951b71' || dashId == '5f7c12fd3965112230dbf2cd' || dashId == '5f702a953965112230dbd29d' || dashId == '5f92c8f85fd2f53f38600333') {
    //   div.style.backgroundColor = "rgb(0, 0, 68)";
    // } else {
    div.style.backgroundColor = "#2291FF";
    // }

    const pdiv = document.createElement('div');
    pdiv.className = "layout row" + i
    pdiv.style.width = "calc(" + width + "% - 4px)";
    pdiv.style.margin = "2px";

    pdiv.appendChild(headerDiv);
    pdiv.appendChild(div);

    return pdiv;
  }
  loadWgt = (db, widgets, divId) => {
    let i = 0;
    const listVal = [];
    widgets.forEach(wgt => {
      i++;
      const div = document.getElementById(wgt);
      div.innerHTML = "";
      const oWgt = db.widgets.get(wgt);
      if (oWgt) {
        const head = document.getElementById("head" + wgt);
        if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
          head.innerHTML = oWgt.title;
        else
          if (head) head.style.display = "none";

        if (!oWgt.container)
          oWgt.container = div;
        else {
          const clone = Object.assign(oWgt);
          clone.$$container = null;
          clone.container = div;
        }
        oWgt.refresh();
        const recurse = () => {
          if (oWgt.refreshing)
            setTimeout(recurse, 1000);
          else {
            if (oWgt.queryResult) {
              let wTxt;
              if (oWgt.queryResult.length) {
                wTxt = oWgt.queryResult[0][0].Value;
              }
              else {
                wTxt = oWgt.queryResult.value ? oWgt.queryResult.value.text : "";
              }


            }
            if (oWgt.$$model.options.drillTarget) {
              const drillDB = oWgt.$$model.options.drillTarget.oid ? oWgt.$$model.options.drillTarget.oid : oWgt.$$model.options.drillTarget
              if (drillDB !== "dummy") {
                div.onclick = () => this.handleModal(drillDB);
                div.style.cursor = "pointer";
              }
            }
            setTimeout(() => { if (div.style.height === "auto") div.style.height = "100%"; }, 2000)

          }
        }
        recurse();
      }
      if (i == widgets.length) {

        db.refresh();
      }
    })
    this.setState({ showLoader: false });

  }

  handleModal = (drillDB) => {
    this.setState({ showDashModal: true })
    this.loadDash("drillDash", drillDB);
  }
  //-----------------------------------------

  configureApp = () => {
    let client = this.props.app.filterObject.tenant.length == 1 ? this.props.app.filterObject.tenant[0] : null;
    let clientId = this.props.app.filterObject.tenantId.length == 1 ? this.props.app.filterObject.tenantId[0] : 0;
    api.get(`activeappstores/` + client + '/' + clientId + '/' + this.props.userDetails.id).then((res) => {
      this.setState({ remainingApps: res })
    });

    api.get(`appstoreconfigs/` + client + '/' + clientId + '/' + this.props.userDetails.id).then((result) => {
      this.setState({ showDashBoard: false, showConfigure: true, selectedAppsAdmin: result });
    });
  }
  handleMaxMin = () => {
    this.setState({ maximizedAppStore: !this.state.maximizedAppStore })
  }

  getImageSrc = (appIcon, iconName) => {
    if (iconName != null) {
      var n = iconName.lastIndexOf('.');
      var extn = iconName.substring(n + 1);
      var imageSrc = extn == 'svg' ? "data:image/svg+xml;base64, " + appIcon : "data:image/jpeg;base64, " + appIcon
      return imageSrc
    }
    else {
      return 'data:image/jpeg;base64, ' + appIcon
    }
  }
  showImage = (imageDetails) => {
    return imageDetails.iconName != null && imageDetails.iconName != '' && imageDetails.appIcon != null && imageDetails.appIcon != ''
  }
  render() {
    const handleSisenseClose = () => this.setState({ showDashModal: false });
    let showModal = () => {
      return (
        <Modal show={this.state.showDashModal} onHide={handleSisenseClose} dialogClassName="modal-90w">
          <Modal.Body><div id="dbModalAppStore" className="db-container"></div>
          </Modal.Body>
        </Modal>
      );
    }
    let availableApps = () => {
      return this.state.remainingApps.map((app, i) => {
        return (
          <div className="appstore-card-container" key={`available` + i}>
            <div className="card-appstore">
              <div className="card-appstore-icon">
                <img src={this.getImageSrc(app.appIcon, app.iconName)} alt="" style={{ visibility: !this.showImage(app) ? 'hidden' : '' }} />
              </div>
              <div className="card-appstore-title">{i18n.t(app.appName)}</div>
              <div className="card-appstore-description">
                {i18n.t(app.appName + "_Desc", app.appDescription)}
              </div>
              <div className="card-appstore-btns">
                <button onClick={() => this.addToCurrent(app)} type="button" className="btn-add">{i18n.t("Add")}<FontAwesomeIcon className="plus-btn" icon={faPlusCircle} /></button>
              </div>
            </div>
          </div>)
      })
    }
    let selectedApps = () => {
      return this.state.selectedAppsAdmin.filter(val => ("isEnabled" in val) ? (val.isEnabled == true) : true).map((app, i) => {
        return (
          <div className="appstore-card-container" key={`remaining` + i}>
            <div className="card-appstore">
              <div className="card-appstore-icon">
                <img src={this.getImageSrc(app.appIcon, app.iconName)} alt="" style={{ visibility: !this.showImage(app) ? 'hidden' : '' }} />
              </div>
              <div className="card-appstore-title">{i18n.t(app.appName)}</div>
              <div className="card-appstore-description">{i18n.t(app.appName + "_Desc", app.appDescription)}</div>
              <div className="card-appstore-btns">
                <button type="button" className="btn-remove" onClick={() => this.removeApp(app)}>{i18n.t("Remove")}<FontAwesomeIcon className="minus-btn" icon={faMinusCircle} /></button>
              </div>
            </div>
          </div>)

      })
    }
    const handleClose = () => this.setState({ showModal: false });
    return (
      // <>
      //   {this.props.isConnected ?
          <div data-test="appStoreComponent">
            <section>
              {(!this.state.showDashBoard && this.state.showConfigure) && (<div className="appstore-container">
                <div className="appstore-header">
                  <h6 className="header-title">{i18n.t("Configure your Apps")}</h6>
                  {(this.state.selectedAppsAdmin.length > 0) ?
                    <div className="app-action-btns">
                      <button type="button" hidden={this.state.hideCancel} onClick={() => this.clearSelected()} className="btn-cancel">Cancel</button>
                      <button type="button" hidden={this.state.hideApply} onClick={() => this.applySelected()} className="btn-apply">Apply</button>

                    </div> : ""}
                </div>
                {(this.state.showModal &&
                  ((this.state.selectedAppsAdmin.filter(val => ("isEnabled" in val) ? (val.isEnabled == true) : true).length == 0)) &&
                  this.state.remainingApps.length != 0) &&
                  (<h6 style={{ color: 'white' }}>{i18n.t("No Apps Configured Yet")}</h6>)}

                {(this.state.showModal &&
                  ((this.state.selectedAppsAdmin.filter(val => ("isEnabled" in val) ? (val.isEnabled == true) : true).length == 0)) &&
                  this.state.remainingApps.length == 0) && (<h6 style={{ color: 'white' }}>{i18n.t("No Apps Available to Configure")}</h6>)}

                {(this.state.selectedAppsAdmin.filter(val => ("isEnabled" in val) ? (val.isEnabled == true) : true).length > 0) && (<div className="appstore-currentApps">
                  <h6>{i18n.t("Your Current Apps")}</h6>
                  <div className="currentApps-section">
                    <div className="row">
                      {selectedApps()}
                    </div>
                  </div>
                </div>)}
                <div className="appstore-availableApps">
                  <h6>{i18n.t("Available Apps for You")}</h6>
                  <div className="availableApps-section">
                    <div className="row">
                      {availableApps()}

                    </div>
                  </div>
                </div>
              </div>)}
              {this.state.showDashBoard && (<div className='container-fluid px-4 tab-pane' >
                <div className="new-subTablist row" data-test='appStoreContainer'>
                  <div className="col-md-12" style={{ paddingRight: '3.5vw' }}>
                    <nav id="appTabs" className="">
                      <div className="row">
                        <Tabs
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          style={{ cursor: "pointer" }}
                        >
                          {this.state.selectedApps.filter(val => ("isEnabled" in val) ? (val.isEnabled == true) : true)
                            .map((tab) => (

                              <div className='d-inline-block'>
                                <div className="media">
                                  {this.showImage(tab) &&
                                    <img className="mr-2 mt-2 rounded-circle" src={this.getImageSrc(tab.appIcon, tab.iconName)} />
                                  }
                                  <div
                                    className={
                                      this.state.activeTab == tab.appId
                                        ? "media-body newTab-text  newTab-text-active"
                                        : "media-body newTab-text"
                                    }
                                    onClick={() => this.setTab(tab)}
                                  >
                                    {i18n.t(tab.appName)}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Tabs>
                      </div>
                    </nav>
                  </div>
                  {(<div style={{ position: 'absolute', right: '2.5vw' }}>
                    <img className="dot-dot-icon" onClick={() => this.configureApp()} src={require("../../Images/hmcc-bars.svg")} />
                  </div>)}
                </div>
                {this.state.activeTab && (<div style={{ overflow: (!this.state.isIframe && this.state.isSisense) ? "auto" : "hidden" }} className={`app-frame-container ${this.state.maximizedAppStore ? " maximizedAppStore" : ""}`}>
                  <FontAwesomeIcon className='float-right' style={{ cursor: "pointer" }} onClick={() => this.handleMaxMin()} color={"#fff"} title={!this.state.maximizedAppStore ? "Maximize" : "Minimize"} icon={this.state.maximizedAppStore ? faCompressAlt : faExpandAlt} />
                  {this.state.isIframe && (<iframe
                    className={!this.state.maximizedAppStore ? "app-store-iframe" : "app-store-iframe-maximized"}
                    sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox allow-downloads"
                    src={this.state.appUrl}
                  >
                  </iframe>)}
                   {this.state.opensInNewTab && !this.state.isIframe && !this.state.isSisense && !this.state.isPowerBi && (<span className="separate-window">{i18n.t("This App opens in separate window")}</span>)}
                  {!this.state.isIframe && this.state.isSisense && (
                    <div id="sisenseAppStore" style={{ paddingTop: "3vh" }}>
                    </div>)
                  }
                  {!this.state.isIframe && this.state.isPowerBi && !this.state.isPowerBiWithoutToken && (
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: this.state.PowerBiReportId,
                        embedUrl: this.state.appUrl,
                        accessToken: null,    // Keep as empty string, null or undefined
                        tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                        settings: {
                          panes: {
                            bookmarks: {
                              visible: false
                            },
                            fields: {
                              expanded: false
                            },
                            filters: {
                              expanded: false,
                              visible: true
                            },
                            pageNavigation: {
                              visible: false
                            },
                            selection: {
                              visible: true
                            },
                            syncSlicers: {
                              visible: true
                            },
                            visualizations: {
                              expanded: false
                            }
                          }
                        }
                      }}
                      cssClassName={!this.state.maximizedAppStore ? "power-bi-frame" : "power-bi-frame-maximized"}
                      getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                        this.props.updatePBToken();
                      }}
                    />)}
                  {!this.state.isIframe && this.state.isPowerBi && this.state.isPowerBiWithoutToken && (
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: null,
                        embedUrl: this.state.appUrl,
                        accessToken: null,    // Keep as empty string, null or undefined
                        tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                        settings: {
                          panes: {
                            bookmarks: {
                              visible: false
                            },
                            fields: {
                              expanded: false
                            },
                            filters: {
                              expanded: false,
                              visible: true
                            },
                            pageNavigation: {
                              visible: false
                            },
                            selection: {
                              visible: true
                            },
                            syncSlicers: {
                              visible: true
                            },
                            visualizations: {
                              expanded: false
                            }
                          }
                        }
                      }}
                      cssClassName={!this.state.maximizedAppStore ? "power-bi-frame" : "power-bi-frame-maximized"}
                    />)}
                </div>)}

                {!this.state.showDashBoard && !this.state.activeTab && (
                  <h5 className="AS-no-apps">{i18n.t("No Apps are Configured")}</h5>
                )}
              </div>)}
              {showModal()}
            </section>
          </div> 
          //: <Connecting />}
      // </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.appReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuDisplay: (boolean) => {
      dispatch(toggleMenuDisplay(boolean));
    },
    setFilters: (obj) => {
      dispatch(setFilters(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppStore);
