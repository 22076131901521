export function toggleMenuDisplay(bool) {
  return {
    type: "SHOW_MENU",
    payload: bool,
  };
}
export function setFilters(obj) {
  return {
    type: "SET_FILTER",
    payload: obj,
  };
}
export function setClients(array) {
  return {
    type: "SET_CLIENTS",
    payload: array,
  };
}
export function setClientOptions(array) {
  return {
    type: "SET_CLIENT_OPTIONS",
    payload: array,
  };
}
export function setUserPreferedLanguage(languageCode, language) {
  return {
    type: "SET_LANGUAGE_CODE",
    payload: languageCode,
    language:language
  };
}
export function setLanguageTranslations(array) {
  return {
    type: "SET_LANGUAGE_TRANSLATIONS",
    payload: array,
  };
}
export function setDemoClient(bool) {
  return {
    type: "SET_DEMO_CLIENT",
    payload: bool,
  };
  }