import React from "react";
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters, setClients, setClientOptions } from "../../actions/AppActions";
import { BASE_URL, ADMIN } from "../../StringConstants";
//import * as api from "../../services/API.js";
import * as api from '../../services/apiAction'
import AuthContext from "../../services/Auth";
import { Hidden } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DateTimeClock from './DateTimeClock'
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import map from "../../services/react-simple-map.json"
import i18n from "i18next";
import { Modal, Form } from "react-bootstrap";
import dateFormat from 'dateformat';
import TextareaAutosize from 'react-textarea-autosize';


const geoUrl = map;
const override = `
position: absolute;
top: 40%;
left: 50%;
z-index: 1000;
`;
//define months array
let months = new Array();
months[0] = "January";
months[1] = "February";
months[2] = "March";
months[3] = "April";
months[4] = "May";
months[5] = "June";
months[6] = "July";
months[7] = "August";
months[8] = "September";
months[9] = "October";
months[10] = "November";
months[11] = "December";

class HumanAndMachine extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            clientsServed: {
                overall: "",
                aapac: "",
                latinAmerica: "",
                northAmerica: "",
                europe: "",
                other: "",
            },
            markers: [],
            showHuman: true,
            showMachine: true,
            humans: {},
            machines: {},
            rawHumanCountObj: {}, //save human count for later use on Human and machine button switch
            rawMachineCountObj: {}, //save machine count for later use on Human and machine button switch
            offeringDropDown: [],
            marketDropDown: [],
            deliveryLocationDropDown: [],
            assetTypeDropDown: [],
            tenantDropDown: [],
            deliveryRegions: {
                HumanMachine: {},
                China: {},
                Europe: {},
                India: {},
                LatinAmerica: {},
                NorthAmerica: {},
                OtherRegions: {},
                Philippines: {},
            },
            filterObject: {
                offering: [],
                market: [],
                deliveryLocation: [],
                assetType: [],
                tenant: [],
                selectedClient: {}
            },
            tempFilterObject: {
                offering: [],
                market: [],
                deliveryLocation: [],
                assetType: [],
                tenant: [],
            },
            openedDropdown: "",
            time: new Date(),
            filteredList: [],
            unfilteredList: [],
            assetTypeFilteredList: [],
            assetTypeUnfilteredList: [],
            deliveryLocationFilteredList: [],
            deliveryLocationUnfilteredList: [],
            marketFilteredList: [],
            marketUnfilteredList: [],
            offeringFilteredList: [],
            offeringUnfilteredList: [],
            hideClientDiv: true,
            hideAssetTypeDiv: true,
            hideDeliveryLocDiv: true,
            hideMarketDiv: true,
            hideOfferingDiv: true,
            selectedClients: [],
            hideFilterHolderDiv: true,
            selectedAssetsTypesLabel: [],
            tempSelectedAssetsTypesLabel: [],
            offeringSearchedText:'',
            marketSearchedText:'',
            deliveryLocationSearchedText:'',
            assetTypeSearchedText:'',
            offeringSelectedAll : false,
            marketSelectedAll : false,
            deliveryLocationSelectedAll: false,
            isActive: {value: true, isValid: true},
            assetTypeSelectedAll : false,
            removedFilter: false,
            selectAllLoading: false,
            profileChecked: false,
            allowAnnouncementAutoPopup: localStorage.getItem('announcementChecked') === 'true' ? true : false,
            profileAutoPopup: false
        };
    }

    
    // newWindow() {
    //     var height = 1;
    //     var width = 1;
    //     var t = window.innerHeight - height
    //     var l = window.innerWidth - width
    //     var win = window.open('https://portal.accenture.com', '', 'height=' + height + ', width=' + width + ', left=' + l + ', top=' + t + 'location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no');
    //     if (win == null || typeof (win) == 'undefined') {
    //     }
    //     else {
    //         this.closeWindow(win);
    //     }
    // }

    // closeWindow(win) {
    //     setTimeout(function () {
    //         win.close();
    //     }, 40000);
    // }

    componentDidMount() {
    //  localStorage.removeItem("announcementChecked");
        // if (this.props.showAnnouncements){
        //     this.setState({allowAnnouncementAutoPopup: false});
        // }
       // this.props.handleProfileBtnClick(this.state.hideFilterHolderDiv);
        // if (this.props.userDetails.isTableauEnabled) {
        //     this.newWindow();
        // }
        window.addEventListener("click", this.handleDropdownClick);
        // let userName = AuthContext.getCachedUser().userName;
        // let enterpriseId = userName.split("@")[0];
        let enterpriseId = this.props.userDetails.userId;
        //Get Filters data
        api.get(`filters`).then((res) => {
            let offering = [];
            let market = [];
            let deliveryLocation = [];
            let assetType = [];
            let tenant = [];
            this.props.setClientOptions(res.tenant)
            if(res.tenant.length>0){
                this.props.onSelectClient(res.tenant[0]);
            }
            res.offering.map((item) => {
                offering.push({ label: item, value: item });
            });
            res.market.map((item) => {
                market.push({ label: item, value: item });
            });
            res.deliveryLocation.map((item) => {
                deliveryLocation.push({ label: item, value: item });
            });
            res.assetType.map((item) => {
                assetType.push({ label: item.assetType, value: item.id });
            });
            res.tenant.map((item) => {
                tenant.push({ label: item.tenantName, value: item.pseudoName });
            });
            this.setState({
                offeringDropDown: offering,
                marketDropDown: market,
                deliveryLocationDropDown: deliveryLocation,
                assetTypeDropDown: assetType,
                tenantDropDown: tenant,
            }, () => this.setSelectedAll());
        });

        //Get ClientServerd data
        api
            .get(`clients`)
            .then((res) => {
                let clientsServed = {
                    overall: "",
                    growthMarkets: "",
                    northAmerica: "",
                    europe: "",
                    other: "",
                };
                res.map((item) => {
                    switch (item.region) {
                        case "OverAll":
                            {
                                clientsServed.overall = item.clients;
                            }
                            break;
                        case "Growth Markets":
                            {
                                clientsServed.growthMarkets = item.clients;
                            }
                            break;
                        case "NA":
                            {
                                clientsServed.northAmerica = item.clients;
                            }
                            break;
                        case "Europe":
                            {
                                clientsServed.europe = item.clients;
                            }
                            break;
                        case "Other":
                            {
                                clientsServed.other = item.clients;
                            }
                            break;
                        default:
                            break;
                    }
                });
             this.setState({ clientsServed });
            });

        //Get co-ordinates
        api.get(`regions`).then((res) => {
            let deliveryRegions = {
                HumanMachine: {},
                China: {},
                Europe: {},
                India: {},
                LatinAmerica: {},
                NorthAmerica: {},
                OtherRegions: {},
                Philippines: {},
            };
            res.map((item) => {
                switch (item.regionName) {
                    case "Human+Machine":
                        {
                            deliveryRegions.HumanMachine = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    case "China/Mainland":
                        {
                            deliveryRegions.China = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    case "Europe":
                        {
                            deliveryRegions.Europe = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    case "India":
                        {
                            deliveryRegions.India = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    case "Latin America":
                        {
                            deliveryRegions.LatinAmerica = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    case "North America":
                        {
                            deliveryRegions.NorthAmerica = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    case "Other Regions":
                        {
                            deliveryRegions.OtherRegions = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    case "Philippines":
                        {
                            deliveryRegions.Philippines = {
                                longitude: item.longitude,
                                latitude: item.latitude,
                                timeZone: item.timeZone,
                            };
                        }
                        break;
                    default:
                        break;
                }
            });
            console.log(this.props.app.filterObject,"prop");
            this.setState({ deliveryRegions }, () => {
                this.getHumanMachineCounts(this.props.app.filterObject);
            });
        });
      

    }
    componentWillReceiveProps(nextProps) {
        if (
            this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant
        ) {
            // this.getHumanMachineCounts(nextProps.app.filterObject);  //Commented for demo instance
        }
        if(this.props.hideFilterHolderDiv != nextProps.hideFilterHolderDiv){
            
                this.handleCloseFilterClick();
            
        }
    }
    componentWillUnmount() {
        window.removeEventListener("click", this.handleDropdownClick);
    }
    setFilters = () => {
        this.props.setFilters(this.props.app.filterObject);
    };

    handleDropdownClick = (event) => {
        if (document.getElementById('dropdown5').contains(event.target) || document.getElementById('dropdown2').contains(event.target) || document.getElementById('dropdown3').contains(event.target)
            || document.getElementById('dropdown4').contains(event.target)
        ) {
            // Clicked in box
        } else {
            // Clicked outside the box
            this.setState({ openedDropdown: "", removedFilter: false });
        }
    }

    getHumanMachineCounts = (data) => {
        this.setState({ loading: true });
        // let userName = AuthContext.getCachedUser().userName;
        // let enterpriseId = userName.split("@")[0];
        let enterpriseId = this.props.userDetails.userId;

        //Get Human machine count data
       
        api
            .post(`hmcounts`, {
                offering: data.offering,
                market: data.market,
                deliveryLocation: data.deliveryLocation,
                assetCategory: data.assetType.map(String),
                pseudoName: []    //data.tenant,
            })
            .then((res) => {
                let humans = {
                    "Human+Machine": 0,
                    "China/Mainland": 0,
                    Europe: 0,
                    India: 0,
                    "Latin America": 0,
                    "North America": 0,
                    "Other Regions": 0,
                    Philippines: 0,
                };
                let machines = {
                    "Human+Machine": 0,
                    "China/Mainland": 0,
                    Europe: 0,
                    India: 0,
                    "Latin America": 0,
                    "North America": 0,
                    "Other Regions": 0,
                    Philippines: 0,
                };
                let emptyObj = {
                    "Human+Machine": 0,
                    "China/Mainland": 0,
                    Europe: 0,
                    India: 0,
                    "Latin America": 0,
                    "North America": 0,
                    "Other Regions": 0,
                    Philippines: 0,
                };
                res.map((item) => {
                    switch (item.deliveryRegion) {
                        case "Human+Machine":
                            {
                                //Get total human and machine count for this region
                                humans["Human+Machine"] = item.human;
                                machines["Human+Machine"] = item.machine;
                            }
                            break;
                        case "China/Mainland":
                            {
                                //Get total human and machine count for this region
                                humans["China/Mainland"] = item.human;
                                machines["China/Mainland"] = item.machine;
                            }
                            break;
                        case "Europe":
                            {
                                //Get total human and machine count for this region
                                humans["Europe"] = item.human;
                                machines["Europe"] = item.machine;
                            }
                            break;
                        case "India":
                            {
                                //Get total human and machine count for this region
                                humans["India"] = item.human;
                                machines["India"] = item.machine;
                            }
                            break;
                        case "Latin America":
                            {
                                //Get total human and machine count for this region
                                humans["Latin America"] = item.human;
                                machines["Latin America"] = item.machine;
                            }
                            break;
                        case "North America":
                            {
                                //Get total human and machine count for this region
                                humans["North America"] = item.human;
                                machines["North America"] = item.machine;
                            }
                            break;
                        case "Other Regions":
                            {
                                //Get total human and machine count for this region
                                humans["Other Regions"] = item.human;
                                machines["Other Regions"] = item.machine;
                            }
                            break;
                        case "Philippines":
                            {
                                //Get total human and machine count for this region
                                humans["Philippines"] = item.human;
                                machines["Philippines"] = item.machine;
                            }
                            break;
                        default:
                            break;
                    }
                });
                this.setState(
                    { rawHumanCountObj: humans, rawMachineCountObj: machines, loading: false },
                    () => {
                        //Check if both buttons are enabled
                        if (this.state.showHuman && this.state.showMachine) {
                            this.setState({ humans, machines }, () => this.setMarkers());

                            // Check only human button is enabled
                        } else if (this.state.showHuman) {
                            this.setState({ humans, machines: emptyObj }, () =>
                                this.setMarkers()
                            );

                            //check only machine button is enabled
                        } else if (this.state.showMachine) {
                            this.setState({ humans: emptyObj, machines }, () =>
                                this.setMarkers()
                            );

                            //Check if both the buttons disabled
                        } else {
                            this.setState({ humans: emptyObj, machines: emptyObj }, () =>
                                this.setMarkers()
                            );
                        }
                    }
                );

            });
    };

    handleHumanMachineCount = (type) => {
        if (!this.state.loading) {
            let humans = {
                "Human+Machine": 0,
                "China/Mainland": 0,
                Europe: 0,
                India: 0,
                "Latin America": 0,
                "North America": 0,
                "Other Regions": 0,
                Philippines: 0,
            };
            let machines = {
                "Human+Machine": 0,
                "China/Mainland": 0,
                Europe: 0,
                India: 0,
                "Latin America": 0,
                "North America": 0,
                "Other Regions": 0,
                Philippines: 0,
            };
            switch (type) {
                case "human":
                    {
                        //check previous state of human button and toggle the value
                        if (this.state.showHuman) {
                            this.setState({ humans, showHuman: false }, () =>
                                this.setMarkers()
                            );
                        } else {
                            this.setState(
                                { humans: this.state.rawHumanCountObj, showHuman: true },
                                () => this.setMarkers()
                            );
                        }
                    }
                    break;
                case "machine":
                    {
                        //check previous state of machine button and toggle the value
                        if (this.state.showMachine) {
                            this.setState({ machines, showMachine: false }, () =>
                                this.setMarkers()
                            );
                        } else {
                            this.setState(
                                { machines: this.state.rawMachineCountObj, showMachine: true },
                                () => this.setMarkers()
                            );
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    };
    setMarkers = () => {
        let markers = [];
        for (let [key, value] of Object.entries(this.state.humans)) {
            //exclude Human+Machine
            if (key !== "Human+Machine") {
                let node = {};
                let name = key
                if (this.state.showHuman)
                    name = name
                if (this.state.showMachine)
                    name = name
                node["name"] = name;
                node["HumanCount"] = value;
                node["MachineCount"] = this.state.machines[key];
                node["latLng"] = this.getCoords(key);
                node["latLng"] = node["latLng"].reverse();
                markers.push(node);
            }
        }
        this.setState({ markers });

    };
    getCoords = (Cont) => {
        var coords = [0, 0];
        switch (Cont) {
            case "China/Mainland":
                coords = [
                    this.state.deliveryRegions.China.longitude,
                    this.state.deliveryRegions.China.latitude,
                ];
                break;
            case "Europe":
                coords = [
                    this.state.deliveryRegions.Europe.longitude,
                    this.state.deliveryRegions.Europe.latitude,
                ];
                break;
            case "India":
                coords = [
                    this.state.deliveryRegions.India.longitude,
                    this.state.deliveryRegions.India.latitude,
                ];
                break;
            case "Latin America":
                coords = [
                    this.state.deliveryRegions.LatinAmerica.longitude,
                    this.state.deliveryRegions.LatinAmerica.latitude,
                ];
                break;
            case "North America":
                coords = [
                    this.state.deliveryRegions.NorthAmerica.longitude,
                    this.state.deliveryRegions.NorthAmerica.latitude,
                ];
                break;
            case "Other Regions":
                coords = [
                    this.state.deliveryRegions.OtherRegions.longitude,
                    this.state.deliveryRegions.OtherRegions.latitude,
                ];
                break;
            case "Philippines":
                coords = [
                    this.state.deliveryRegions.Philippines.longitude,
                    this.state.deliveryRegions.Philippines.latitude,
                ];
                break;
        }
        return coords;
    };
    setSelectedAll = () => {
        let filterArray = [...this.state.filterObject.offering]; 
        let filterArray1 = [...this.state.filterObject.market];
        let filterArray2 = [...this.state.filterObject.deliveryLocation];
        let filterArray3 = [...this.state.filterObject.assetType];
        let selectedAssetsTypesLabel = [...this.state.selectedAssetsTypesLabel]

        filterArray = this.state.offeringDropDown.map(i => 
            i.value
        );
        filterArray1 = this.state.marketDropDown.map(i => 
            i.value
        );
        filterArray2 = this.state.deliveryLocationDropDown.map(i => 
            i.value
        );
        filterArray3 = this.state.assetTypeDropDown.map(i => 
            i.value
        ); 
        selectedAssetsTypesLabel = this.state.assetTypeDropDown.map(i => i);
      //  console.log(this.props.app.filterObject,"selectall");
       
        this.setState({
                filterObject: {
                    ...this.state.filterObject,
                    offering: filterArray,
                    market : filterArray1,
                    deliveryLocation: filterArray2,
                    assetType: filterArray3
                },
                selectedAssetsTypesLabel: selectedAssetsTypesLabel,
                offeringSelectedAll : true,
                marketSelectedAll: true,
                deliveryLocationSelectedAll: true,
                assetTypeSelectedAll: true,
                selectAllLoading: true
                
        },()=>{this.applyMainFilters()})
    }
    toggleDropdown = (value) => {
        if (!this.state.loading) {
            if (this.state.openedDropdown == value) {
                this.setState({ openedDropdown: "" });
            } else {
                switch (value) {
                    case "offering": {
                        this.setState({offeringSearchedText:'',offeringSelectedAll:  this.state.filterObject.offering.length === this.state.offeringDropDown.length, openedDropdown: "offering", hideClientDiv: true, hideAssetTypeDiv: true, hideDeliveryLocDiv: true, hideMarketDiv: true, hideOfferingDiv: false, offeringFilteredList: this.state.offeringDropDown, offeringUnfilteredList: this.state.offeringDropDown});
                    }
                        break;
                    case "market": {
                        this.setState({marketSearchedText:'',  marketSelectedAll: this.state.filterObject.market.length === this.state.marketDropDown.length, openedDropdown: "market", hideClientDiv: true, hideAssetTypeDiv: true, hideDeliveryLocDiv: true, hideMarketDiv: false, hideOfferingDiv: true, marketFilteredList: this.state.marketDropDown, marketUnfilteredList: this.state.marketDropDown });
                    }
                        break;
                    case "deliveryLocation": {
                        this.setState({deliveryLocationSearchedText:'', deliveryLocationSelectedAll: this.state.filterObject.deliveryLocation.length === this.state.deliveryLocationDropDown.length, openedDropdown: "deliveryLocation", hideClientDiv: true, hideAssetTypeDiv: true, hideDeliveryLocDiv: false, hideMarketDiv: true, hideOfferingDiv: true, deliveryLocationFilteredList: this.state.deliveryLocationDropDown, deliveryLocationUnfilteredList: this.state.deliveryLocationDropDown });
                    }
                        break;
                    case "assetType": {
                        this.setState({assetTypeSearchedText:'', assetTypeSelectedAll: this.state.filterObject.assetType.length === this.state.assetTypeDropDown.length, openedDropdown: "assetType", hideClientDiv: true, hideAssetTypeDiv: false, hideDeliveryLocDiv: true, hideMarketDiv: true, hideOfferingDiv: true, assetTypeFilteredList: this.state.assetTypeDropDown, assetTypeUnfilteredList: this.state.assetTypeDropDown });
                    }
                        break;
                    case "tenant": {
                        this.setState({ openedDropdown: "tenant", hideClientDiv: false, hideAssetTypeDiv: true, hideDeliveryLocDiv: true, hideMarketDiv: true, hideOfferingDiv: true, filteredList: this.state.tenantDropDown });
                    }
                        break;
                    default:
                        break;
                }
            }
        }
    };
  
    handleFilterSelect = (type, element) => {
        if (!this.state.loading) {
            let filterArray = [];
            let clientArray = [];
            switch (type) {
                case "offering":
                    {
                        let OfferingList = this.state.offeringFilteredList.map(i => i.value)
                        filterArray = [...this.state.filterObject.offering]; 
                        if (filterArray.some(item => item == element.value)) {
                            let index = filterArray.indexOf(element.value);
                            if (index > -1) {
                                filterArray.splice(index, 1);
                                this.setState({offeringSelectedAll: false})
                            }
                        } else {
                            if (element.value == "Select All" && !this.state.offeringSelectedAll) {
                                filterArray = this.state.offeringFilteredList.map(i => 
                                     i.value
                                );
                                this.setState({offeringSelectedAll: true})
                            } else if (element.value == "Select All" && this.state.offeringSelectedAll) {
                                filterArray = []
                                this.setState({offeringSelectedAll: false})
                            }
                            else {
                                filterArray.push(element.value);
                                this.setState({offeringSelectedAll: OfferingList.length === filterArray.length})
                            }
                        }
                        this.setState(
                            {
                                filterObject: {
                                    ...this.state.filterObject,
                                    offering: filterArray,
                                },
                            })

                    }
                    break;
                case "market":
                    {
                        let marketList = this.state.marketFilteredList.map(i => i.value)
                        filterArray = [...this.state.filterObject.market];
                        if (filterArray.some(item => item == element.value)) {
                            let index = filterArray.indexOf(element.value);
                            if (index > -1) {
                                filterArray.splice(index, 1);
                                this.setState({marketSelectedAll : false })
                            }
                        } else {
                            if (element.value == "Select All" && !this.state.marketSelectedAll) {
                                filterArray = this.state.marketFilteredList.map(i => 
                                     i.value
                                );
                                this.setState({marketSelectedAll : true })
                            } else if(element.value == "Select All" && this.state.marketSelectedAll) {
                                filterArray = []
                               this.setState({marketSelectedAll : false })
                            }
                            else {
                                filterArray.push(element.value);
                                this.setState({marketSelectedAll: marketList.length === filterArray.length})
                            }
                        }
                        this.setState(
                            {
                                filterObject: {
                                    ...this.state.filterObject,
                                    market: filterArray,
                                },
                            })

                    }
                    break;
                case "deliveryLocation":
                    {
                        let deliveryLocationList = this.state.deliveryLocationFilteredList.map(i => i.value)
                        filterArray = [...this.state.filterObject.deliveryLocation];
                        if (filterArray.some(item => item == element.value)) {
                            let index = filterArray.indexOf(element.value);
                            if (index > -1) {
                                filterArray.splice(index, 1);
                                this.setState({deliveryLocationSelectedAll : false})
                            }
                        } else {
                            if (element.value == "Select All" && !this.state.deliveryLocationSelectedAll) {
                                filterArray = this.state.deliveryLocationFilteredList.map(i => 
                                     i.value
                                );
                                this.setState({deliveryLocationSelectedAll : true})
                            } else if (element.value == "Select All" && this.state.deliveryLocationSelectedAll) {
                                filterArray = []
                               this.setState({deliveryLocationSelectedAll : false})
                            } 
                            else {
                                filterArray.push(element.value);
                                this.setState({deliveryLocationSelectedAll: deliveryLocationList.length === filterArray.length})
                            }                        
                        }
                        this.setState(
                            {
                                filterObject: {
                                    ...this.state.filterObject,
                                    deliveryLocation: filterArray,
                                },
                            })
                    }
                    break;
                case "assetType":
                    {
                        let assetTypeList = this.state.assetTypeFilteredList.map(i => i.value)
                        filterArray = [...this.state.filterObject.assetType];
                        let selectedAssetsTypesLabel = [...this.state.selectedAssetsTypesLabel]
                        if (filterArray.some(item => item == element.value)) {
                            let index = filterArray.indexOf(element.value);
                            if (index > -1) {
                                filterArray.splice(index, 1);
                                selectedAssetsTypesLabel.splice(index, 1)
                                this.setState({assetTypeSelectedAll : false}) 
                            }
                        } else {
                            if (element.value == "Select All" && !this.state.assetTypeSelectedAll) {
                                filterArray = this.state.assetTypeFilteredList.map(i => 
                                     i.value
                                );
                                selectedAssetsTypesLabel = this.state.assetTypeFilteredList.map(i => i);
                                this.setState({assetTypeSelectedAll : true})
                            } else if (element.value == "Select All" && this.state.assetTypeSelectedAll) {
                                filterArray = []
                                selectedAssetsTypesLabel = []
                                this.setState({assetTypeSelectedAll : false}) 
                            } else {
                                filterArray.push(element.value);
                                selectedAssetsTypesLabel.push(element);
                                this.setState({assetTypeSelectedAll: assetTypeList.length === filterArray.length})
                            }
                        }
                        this.setState(
                            {
                                filterObject: {
                                    ...this.state.filterObject,
                                    assetType: filterArray,
                                },
                                selectedAssetsTypesLabel: selectedAssetsTypesLabel
                            })
                    }
                    break;
                case "tenant":
                    {
                        filterArray = [...this.state.filterObject.tenant];
                        if (filterArray.some(item => item == element.value)) {
                            let index = filterArray.indexOf(element.value);
                            if (index > -1) {
                                filterArray.splice(index, 1);
                            }
                        } else {
                            filterArray.push(element.value);
                        }

                        //client list(Not pseudo)
                        clientArray = [...this.state.selectedClients];
                        if (clientArray.some(item => item == element.label)) {
                            let index = clientArray.indexOf(element.label);
                            if (index > -1) {
                                clientArray.splice(index, 1);
                            }
                        } else {
                            clientArray.push(element.label);
                        }

                        this.setState(
                            {
                                filterObject: {
                                    ...this.state.filterObject,
                                    tenant: filterArray,
                                },
                                selectedClients: clientArray
                            })


                    }
                    break;
                default:
                    break;
            }

        }
    }
    applyMainFilters = () => {
        if (this.props.isLoggingEnabled) {
            let previousSelectedLocations = [...this.state.tempFilterObject.deliveryLocation]
            let selectedLocations = [...this.state.filterObject.deliveryLocation]
            this.getChangedLocationFilters(previousSelectedLocations, selectedLocations)
        }


        let filterObject = { ...this.state.filterObject, tenant: this.props.app.filterObject.tenant, tenantId:this.props.app.filterObject.tenantId }

        this.props.setFilters(filterObject);
        if(this.state.offeringSelectedAll && this.state.marketSelectedAll && this.state.deliveryLocationSelectedAll && this.state.assetTypeSelectedAll)
        {
            let filterObject1= {
                offering: [],
                market: [],
                deliveryLocation: [],
                assetType: [],
                tenant: this.props.app.filterObject.tenant,
            }
             this.getHumanMachineCounts(filterObject1)

        }
        else{
        
             this.getHumanMachineCounts(filterObject)
        }   
     //   this.getHumanMachineCounts(filterObject) 
        this.setState({
            hideFilterHolderDiv: true
        })
    }

    getChangedLocationFilters = (previousSelectedLocations, selectedLocations) => {
        let difference = previousSelectedLocations
            .filter(x => !selectedLocations.includes(x))
            .concat(selectedLocations.filter(x => !previousSelectedLocations.includes(x)));

        this.props.onChangeDeliveryLocations(difference)
    }

    applyFilter = (filterType) => {
        this.toggleDropdown(filterType)
       //this.setState({removedFilter: false}, () => ) 
    }
    clearMainFilters = () => {
        this.setState({
            filterObject: {
                offering: [],
                market: [],
                deliveryLocation: [],
                assetType: [],
                tenant: this.props.app.filterObject.tenant,
            },
            hideFilterHolderDiv: true,
            tempFilterObject: {
                offering: [],
                market: [],
                deliveryLocation: [],
                assetType: [],
                tenant: this.props.app.filterObject.tenant,
            },
            selectedClients: [],
            selectedAssetsTypesLabel: [],
            tempSelectedAssetsTypesLabel: []
        })
        this.props.setFilters({
            offering: [],
            market: [],
            deliveryLocation: [],
            assetType: [],
            tenant: this.props.app.filterObject.tenant,
            tenantId:this.props.app.filterObject.tenantId
        });

        this.getHumanMachineCounts({
            offering: [],
            market: [],
            deliveryLocation: [],
            assetType: [],
            tenant: this.props.app.filterObject.tenant,
        })


    }
    clearFilter = (filterType) => {
        let filterObject = { ...this.state.filterObject }
        filterObject[filterType] = []
        let selectedAssetsTypesLabel = [...this.state.selectedAssetsTypesLabel]
        if (filterType == 'assetType') {
            selectedAssetsTypesLabel = []
            this.setState({
                assetTypeSelectedAll: false 
            })
        } else if(filterType == 'offering') {
            this.setState({
                offeringSelectedAll: false 
            })
        } else if(filterType == 'market') {
            this.setState({
                marketSelectedAll: false 
            })
        } else if(filterType == 'deliveryLocation') {
            this.setState({
                deliveryLocationSelectedAll: false 
            })
        }
        this.setState({
            selectedAssetsTypesLabel: selectedAssetsTypesLabel,
            filterObject: filterObject,
            [filterType+'SearchedText']:'',
            [filterType+'FilteredList']:this.state[filterType+'UnfilteredList']
        })
    }
    searchFunction = (element, filter) => {
        let a, i;
        let modified_list = [];
        let unmodified_list = [];
        switch (element) {
            case "clintDropdown": {
                a = this.state.tenantDropDown;
                if (filter.length > 0) {
                    for (i = 0; i < a.length; i++) {
                        let txtValue = a[i].label;
                        if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            a.map((item) => {
                                if (txtValue == item.label) {
                                    modified_list.push({ label: item.label, value: item.value });
                                }
                            });
                        } else {
                            a.map((item) => {
                                if (txtValue == item.label) {
                                    unmodified_list.push({ label: item.label, value: item.value });
                                }
                            });
                        }
                    }
                    this.setState({ filteredList: modified_list, unfilteredList: unmodified_list });
                } else {
                    this.setState({ filteredList: a });
                }
            }
                break;
            case "assetTypeDropdown": {
                a = this.state.assetTypeDropDown;
                if (filter.length > 0) {
                    this.setState({ assetTypeFilteredList: a.filter(x => x.label.toLowerCase().includes(filter.toLowerCase())), assetTypeUnfilteredList: a, assetTypeSearchedText:filter }, () => this.setFilterArrayOnSearch(element, filter, this.state.assetTypeFilteredList));
                } else {
                    this.setState({ assetTypeFilteredList: a, assetTypeSearchedText:'' }, () => this.setFilterArrayOnSearch(element, filter, this.state.assetTypeFilteredList));
                }
            }
                break;
            case "deliveryLocDropdown": {
                a = this.state.deliveryLocationDropDown;
                if (filter.length > 0) {
                    this.setState({ deliveryLocationFilteredList: a.filter(x => x.label.toLowerCase().includes(filter.toLowerCase())), deliveryLocationUnfilteredList: a, deliveryLocationSearchedText: filter }, () => this.setFilterArrayOnSearch(element, filter, this.state.deliveryLocationFilteredList));
                } else {
                    this.setState({ deliveryLocationFilteredList: a, deliveryLocationSearchedText:'' }, () => this.setFilterArrayOnSearch(element, filter, this.state.deliveryLocationFilteredList));
                }
            }
                break;
            case "marketDropdown": {
                a = this.state.marketDropDown;
                if (filter.length > 0) {
                    this.setState({ marketFilteredList: a.filter(x => x.label.toLowerCase().includes(filter.toLowerCase())), marketUnfilteredList: a, marketSearchedText: filter }, () => this.setFilterArrayOnSearch(element, filter, this.state.marketFilteredList));
                } else {
                    this.setState({ marketFilteredList: a, marketSearchedText:'' }, () => this.setFilterArrayOnSearch(element, filter, this.state.marketFilteredList));
                }
            }
                break;
            case "offeringDropdown": {
                a = this.state.offeringDropDown;
                if (filter.length > 0) {
                    this.setState({ offeringFilteredList: a.filter(x => x.label.toLowerCase().includes(filter.toLowerCase())), offeringUnfilteredList: a, offeringSearchedText: filter }, () => this.setFilterArrayOnSearch(element, filter, this.state.offeringFilteredList));
                } else {
                    this.setState({ offeringFilteredList: a, offeringSearchedText:'' }, () => this.setFilterArrayOnSearch(element, filter, this.state.offeringFilteredList));
                }
            }
                break;
            default:
                break;
        }
    }

    setFilterArrayOnSearch = (element, filter, filteredList) => {
            switch(element) {
                case 'offeringDropdown': {
                    let offeringFilteredSearched = [];
                    if(filter.length > 0) {
                        filteredList.forEach((item) => this.state.filterObject.offering.map(v => {if(v == item.value) {offeringFilteredSearched.push(item.value)}} ))
                    } else {
                        offeringFilteredSearched = this.state.offeringFilteredList.map(i => i.value)
                        this.setState({offeringSelectedAll: true})
                    }
                    this.setState(
                        {
                            filterObject: {
                                ...this.state.filterObject,
                                offering: offeringFilteredSearched,
                            },

                        }, () => this.setState({offeringSelectedAll: this.state.filterObject.offering.length === filteredList.length})
                    )
                }
                break;
                case 'marketDropdown': {
                    let marketFilteredSearched = [];
                    if(filter.length > 0) {
                        filteredList.forEach((item) => this.state.filterObject.market.map(v => {if(v == item.value) {marketFilteredSearched.push(item.value)}} ))
                    } else {
                        marketFilteredSearched = this.state.marketFilteredList.map(i => i.value)
                        this.setState({marketSelectedAll: true})
                    }
                    this.setState(
                        {
                            filterObject: {
                                ...this.state.filterObject,
                                market: marketFilteredSearched,
                            },

                        }, () => this.setState({marketSelectedAll: this.state.filterObject.market.length === filteredList.length})
                    )
                }
                break;
                case 'deliveryLocDropdown': {
                    let deliveryLocFilteredSearched = [];
                    if(filter.length > 0) {
                        filteredList.forEach((item) => this.state.filterObject.deliveryLocation.map(v => {if(v == item.value) {deliveryLocFilteredSearched.push(item.value)}} ))
                    } else {
                        deliveryLocFilteredSearched = this.state.deliveryLocationFilteredList.map(i => i.value)
                        this.setState({deliveryLocationSelectedAll: true})
                    }
                    this.setState(
                        {
                            filterObject: {
                                ...this.state.filterObject,
                                deliveryLocation: deliveryLocFilteredSearched,
                            },

                        }, () => this.setState({deliveryLocationSelectedAll: this.state.filterObject.deliveryLocation.length === filteredList.length})
                    )
                }
                break;
                case 'assetTypeDropdown': {
                        let assetTypeFilteredSearched = [];
                        let selectedAssetsTypesLabel = []

                        if(filter.length > 0) {
                            filteredList.forEach((item) => this.state.filterObject.assetType.map(v => {if(v == item.value) {assetTypeFilteredSearched.push(item.value); selectedAssetsTypesLabel.push(item)}} ))
                        } else {
                            assetTypeFilteredSearched = this.state.assetTypeFilteredList.map(i => i.value)
                            selectedAssetsTypesLabel = this.state.assetTypeFilteredList.map(i => i)
                            this.setState({assetTypeSelectedAll: true})
                        }

                        this.setState(
                            {
                                filterObject: {
                                    ...this.state.filterObject,
                                    assetType : assetTypeFilteredSearched,
                                },
                                selectedAssetsTypesLabel: selectedAssetsTypesLabel
                            }, () => this.setState({assetTypeSelectedAll: this.state.filterObject.assetType.length === filteredList.length}))
                }
                break;
            } 
    }

    handleFilterBtnClick = () => {
        this.setState({
            hideFilterHolderDiv: false,
            tempFilterObject: { ...this.state.filterObject },
            tempSelectedAssetsTypesLabel: [...this.state.selectedAssetsTypesLabel]
        })
    }

    handleCloseFilterClick = () => {
        this.setState({
            hideFilterHolderDiv: true,
            filterObject: { ...this.state.tempFilterObject },
            selectedAssetsTypesLabel: [...this.state.tempSelectedAssetsTypesLabel]
        })
       
    }
    removeSelectedFilter = (name, element) => {
        let data = { value: element }
        this.setState({removedFilter : true}, () => this.handleFilterSelect(name, data))
    }

    handleNotificationChange=(value)=>{
        this.setState({allowAnnouncementAutoPopup: value? true : false})

        let allowAnnouncement= this.props.userDetails.id;
        this.props.showPopUp(allowAnnouncement,!value);
        localStorage.setItem('announcementChecked', value.toString());
    }

    

    render() {
//console.log(this.props.announcements, 'this.props.announcements')
        let handleAnnouncementClose = ()=>{
            this.props.switchHumanAndMachineTab(false);
        }
        let handleAnnouncementCloseProfile = ()=>{
            this.props.announcementPopUp(false);
        }
        
        let nextTick = (cb) => {
            if (typeof window.Promise === 'function') {
                Promise.resolve().then(cb);
            } else if (typeof window.setImmediate === 'function') {
                setImmediate(cb);
            } else {
                setTimeout(cb, 0);
            }
        }

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                borderBottom: "1px dotted pink",
                color: state.isSelected ? "green" : "blue",
                backgroundColor: "transparent",
            }),
            placeholder: () => ({
                // none of react-select's styles are passed to <Control />
                fontSize: "14px",
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />

            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = "opacity 300ms";

                return { ...provided, opacity, transition };
            },
        };

        const ValueContainer = ({ children, getValue, ...props }) => {
            var length = getValue().length;

            return (
                <div {...props}>
                    {!props.selectProps.menuIsOpen &&
                        `${props.selectProps.placeholder} (${length})`}
                    {React.cloneElement(children[1])}
                </div>
            );
        };

        //Construct dropdown options for filters
        let offeringDropDownList = [];
        offeringDropDownList.push(
            <div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("offering", {label: "Select All", value: "Select All"})}>
                    <input type="checkbox" checked={this.state.offeringSelectedAll} className="" value={"Select All"} name="optradio" />
                    <label for="">{"Select All"}</label>
            </div>
        )
        if (this.state.offeringFilteredList.length > 0) {
             this.state.offeringFilteredList.forEach((item) => (
                offeringDropDownList.push(<div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("offering", item)}>
                    <input type="checkbox" checked={this.state.filterObject.offering.some(v => v == item.value) ? true : false} className="" value={item.value} name="optradio" />
                    <label for="">{item.label}</label>
                </div>)
            ));
        } else {
            offeringDropDownList = [<div className="checkbox-dropdown-items no-results">
                <label for="">{i18n.t('No Results Found')}</label>
            </div>]
        }

        let marketDropDownList = [];
        marketDropDownList.push(
            <div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("market", {label: "Select All", value: "Select All"})}>
                    <input type="checkbox" checked={this.state.marketSelectedAll} className="" value={"Select All"} name="optradio" />
                    <label for="">{"Select All"}</label>
                </div>
        )
        if (this.state.marketFilteredList.length > 0) {
             this.state.marketFilteredList.forEach((item) => (
                marketDropDownList.push(<div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("market", item)}>
                    <input type="checkbox" checked={this.state.filterObject.market.some(v => v == item.value) ? true : false} className="" value={item.value} name="optradio" />
                    <label for="">{item.label}</label>
                </div>)
            ));
        } else {
            marketDropDownList = [<div className="checkbox-dropdown-items no-results">
                <label for="">{i18n.t('No Results Found')}</label>
            </div>]
        }

        let deliveryLocationDropDownList = [];
        deliveryLocationDropDownList.push(
            <div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("deliveryLocation", {label: "Select All", value: "Select All"})}>
                    <input type="checkbox" checked={this.state.deliveryLocationSelectedAll} className="" value={"Select All"} name="optradio" />
                    <label for="">{"Select All"}</label>
                </div>
        )
        if (this.state.deliveryLocationFilteredList.length > 0) {
             this.state.deliveryLocationFilteredList.forEach((item) => (
                deliveryLocationDropDownList.push(<div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("deliveryLocation", item)}>
                    <input type="checkbox" checked={this.state.filterObject.deliveryLocation.some(v => v == item.value) ? true : false} className="" value={item.value} name="optradio" />
                    <label for="">{item.label}</label>
                </div>)
            ));
        } else {
            deliveryLocationDropDownList = [<div className="checkbox-dropdown-items no-results">
                <label for="">{i18n.t('No Results Found')}</label>
            </div>]
        }

        let assetTypeDropDownList = [];
        assetTypeDropDownList.push (
            <div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("assetType", {label: "Select All", value: "Select All"})}>
                    <input type="checkbox" checked={this.state.assetTypeSelectedAll} className="" value={"Select All"} name="optradio" />
                    <label for="">{"Select All"}</label>
                </div>
        )
        if (this.state.assetTypeFilteredList.length > 0) {
             this.state.assetTypeFilteredList.forEach((item) => (
                assetTypeDropDownList.push( <div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("assetType", item)}>
                    <input type="checkbox" checked={this.state.filterObject.assetType.some(v => v == item.value) ? true : false} className="" value={item.value} name="optradio" />
                    <label for="">{item.label}</label>
                </div>)
            ));
        } else {
            assetTypeDropDownList = [<div className="checkbox-dropdown-items no-results">
                <label for="">{i18n.t('No Results Found')}</label>
            </div>]
        }

        let tenantDropDownList = [];
        if (this.state.filteredList.length > 0) {
            tenantDropDownList = this.state.filteredList.map((item) => (
                <div className="checkbox-dropdown-items" onClick={() => this.handleFilterSelect("tenant", item)}>
                    <input type="checkbox" checked={this.state.filterObject.tenant.some(v => v == item.value) ? true : false} className="" value={item.value} name="optradio" />
                    <label for="">{item.label}</label>
                </div>
            ));
        } else {
            tenantDropDownList = [<div className="checkbox-dropdown-items no-results">
                <label for="">{i18n.t('No Results Found')}</label>
            </div>]
        }

        return (
            <div data-test='human-machine-component'>
                <div className={this.state.loading ? "loading" : "hidden"}>
                    <img
                        className="loader-size"
                        src={require("../../Images/WO-background.gif")}
                        alt=""
                    />
                </div>
                {/*  HUMAN + MACHINE Start */}
                <section className="second-nav" style={{ position: "inherit" }}>
                    <div className="HMFilter-btn-holder" onClick={() => this.handleFilterBtnClick()} data-test='filter-icon'>
                            <img className="" src={
                                (this.props.app.filterObject.offering.length > 0 ||
                                    this.props.app.filterObject.market.length > 0 ||
                                    this.props.app.filterObject.deliveryLocation.length > 0 ||
                                    this.props.app.filterObject.assetType.length > 0
                                )
                                    ?
                                    require("../../Images/color-filter.svg") : require("../../Images/hmcc-filter.svg")
                            } />
                        </div>
                    <div className='container transparent-bg-top'>
                        <div className="row text-center">
                            <div className="col-md-12 col-lg-12">
                                <div className=" hmcc-d-flex box-margin text-white">
                                    <div className="hmcc-flex-child">
                                        <h6 className="hmcc-text media">
                                            {" "}
                                            <img
                                                className="mr-1"
                                                src={require("../../Images/ClientServed_icon.svg")}
                                            />{" "}
                                            <span className="clientServed media-body">{i18n.t(this.props.config[0].displayName)}</span>

                                        </h6>
                                    </div>

                                    <div className="hmcc-flex-child">
                                        <h6>{i18n.t('Overall')}</h6>
                                        <h5 className="info-text">
                                            {this.state.clientsServed.overall}
                                        </h5>
                                    </div>
                                    <div className="hmcc-flex-child">
                                        <h6>{i18n.t('Growth Markets')}</h6>
                                        <h5 className="info-text">
                                            {this.state.clientsServed.growthMarkets}
                                        </h5>
                                    </div>
                                    <div className="hmcc-flex-child">
                                        <h6>{i18n.t('Europe')}</h6>
                                        <h5 className="info-text">
                                            {this.state.clientsServed.europe}
                                        </h5>
                                    </div>
                                    <div className="hmcc-flex-child">
                                        <h6> {i18n.t('North America')}</h6>
                                        <h5 className="info-text">
                                            {this.state.clientsServed.northAmerica}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                    <div className="container">
                    </div>
                    <div className="container hmcc-dd-section">
                        <div className="row">
                            <div id="listclose" className="col-lg-12 mobile-view">
                                <div>
                                    <div className="dropbtn-parent filter-vertical-align filter-dropbtn-parent "
                                        hidden={this.state.hideFilterHolderDiv}
                                        data-test='filter-div'
                                    >

                                        <div className="row filter-header">
                                            <div className="filter-title">
                                                <img className="" src={
                                                    (this.props.app.filterObject.offering.length > 0 ||
                                                        this.props.app.filterObject.market.length > 0 ||
                                                        this.props.app.filterObject.deliveryLocation.length > 0 ||
                                                        this.props.app.filterObject.assetType.length > 0
                                                    ) ? require("../../Images/color-filter.svg") : require("../../Images/hmcc-filter.svg")
                                                } />
                                                <h6>{i18n.t('Filters')}</h6>
                                            </div>
                                            <div>
                                                <img
                                                    className="filterCloseBtn"
                                                    src={require("../../Images/hmcc-circle-close.svg")}
                                                    onClick={() => this.handleCloseFilterClick()}
                                                />
                                            </div>
                                        </div>

                                        <div className="dropdown" id="dropdown5">
                                            <button
                                                className={
                                                    this.state.openedDropdown == "offering"
                                                        ? "hmcc-drop-btn map-filter map-filter-appliedFilters"
                                                        : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg"
                                                }
                                                onClick={() => this.toggleDropdown("offering")}
                                            >
                                                <div className={this.state.openedDropdown != "offering" && this.state.filterObject.offering.length > 0 ? 'FilterType mt-2' : ''}>
                                                    {this.props.app.filterObject.offering.length > 0
                                                        ? `${"Offering (" + this.props.app.filterObject.offering.length + ")"}`
                                                        : i18n.t('Offering')}{" "}
                                                </div>
                                                <img className={this.state.openedDropdown != "offering" && this.state.filterObject.offering.length > 0 ? 'mt-2' : ''} src={require("../../Images/drop-down-arrow.png")} />{" "}
                                                <div hidden={this.state.openedDropdown === "offering" || !this.state.filterObject.offering.length > 0} className='row m-0 mt-2'>
                                                    {this.state.filterObject.offering.slice(0, 3).map((data, index) => {
                                                        return (
                                                            <span className='hmcc-drop-selectedItems'>{data}
                                                                <img id='deleteIcon' onClick={() => this.removeSelectedFilter("offering", data)}

                                                                    src={require("../../Images/deleteSelected.svg")} />
                                                            </span>)
                                                    })}
                                                    {this.state.filterObject.offering.length > 3 ? '+' + (this.state.filterObject.offering.length - 3) : null}

                                                </div>
                                            </button>
                                            <div
                                                className="hmcc-dd-options"
                                                hidden={this.state.openedDropdown == "offering" ? this.state.hideOfferingDiv : true}
                                            >
                                                <div
                                                    id="searchbox"
                                                    className="hmcc-searchbox"
                                                    hidden={this.state.openedDropdown == "offering" ? this.state.hideOfferingDiv : true}
                                                >
                                                    {this.state.openedDropdown == "offering" ? (
                                                        <input
                                                            type="text"
                                                            className="checkbox-dropdown-items"
                                                            placeholder={i18n.t('Search') + '..'}
                                                            id="myInput"
                                                            value={this.state.offeringSearchedText}
                                                            onChange={(e) => this.searchFunction("offeringDropdown",e.target.value)}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div
                                                    id="myDropdown"
                                                    className={
                                                        this.state.openedDropdown == "offering" ? "dropdown-content show" : "dropdown-content"
                                                    }
                                                >
                                                    {offeringDropDownList}
                                                </div>
                                                <div id="myDropdownFooter" className='hmcc-dd-options-footer'>
                                                    <button
                                                        className="hmcc-drop-btn clearBtn"
                                                        onClick={() => this.clearFilter('offering')}

                                                    >
                                                        {i18n.t('Clear')}
                                                    </button>
                                                    <button
                                                        className="hmcc-drop-btn applyBtn"
                                                        onClick={() => this.applyFilter('offering')}
                                                    >
                                                        {i18n.t('Apply')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Hidden this filter */}
                                        {/* <div className="dropdown" id="dropdown2">
                                            <button
                                                className={
                                                    this.state.openedDropdown == "market"
                                                        ? "hmcc-drop-btn map-filter map-filter-appliedFilters"
                                                        : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg"
                                                }
                                                onClick={() => this.toggleDropdown("market")}
                                            >
                                                <div className={this.state.openedDropdown != "market" && this.state.filterObject.market.length > 0 ? 'FilterType mt-2' : ''}>
                                                    {this.props.app.filterObject.market.length > 0
                                                        ? `${"Market (" + this.props.app.filterObject.market.length + ")"}`
                                                        : i18n.t('Market')}{" "}</div>
                                                <img className={this.state.openedDropdown != "market" && this.state.filterObject.market.length > 0 ? 'mt-2' : ''} src={require("../../Images/drop-down-arrow.png")} />{" "}
                                                <div hidden={this.state.openedDropdown === "market" || !this.state.filterObject.market.length > 0} className='row m-0 mt-2'>
                                                    {this.state.filterObject.market.slice(0, 3).map((data, index) => {
                                                        return (
                                                            <span className='hmcc-drop-selectedItems'>{data}
                                                                <img id='deleteIcon' onClick={() => this.removeSelectedFilter("market", data)}

                                                                    src={require("../../Images/deleteSelected.svg")} />
                                                            </span>)
                                                    })}
                                                    {this.state.filterObject.market.length > 3 ? '+' + (this.state.filterObject.market.length - 3) : null}

                                                </div>
                                            </button>
                                            <div
                                                className="hmcc-dd-options"
                                                hidden={this.state.openedDropdown == "market" ? this.state.hideMarketDiv : true}
                                            >
                                                <div
                                                    id="searchbox1"
                                                    className="hmcc-searchbox"
                                                    hidden={this.state.openedDropdown == "market" ? this.state.hideMarketDiv : true}
                                                >
                                                    {this.state.openedDropdown == "market" ? (
                                                        <input
                                                            type="text"
                                                            className="checkbox-dropdown-items"
                                                            placeholder={i18n.t('Search') + '..'}
                                                            id="myInput1"
                                                            value={this.state.marketSearchedText}
                                                            onChange={(e) => this.searchFunction("marketDropdown",e.target.value)}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div
                                                    id="myDropdown1"
                                                    className={
                                                        this.state.openedDropdown == "market" ? "dropdown-content show" : "dropdown-content"
                                                    }
                                                >
                                                    {marketDropDownList}
                                                </div>
                                                <div id="myDropdownFooter" className='hmcc-dd-options-footer'>
                                                    <button
                                                        className="hmcc-drop-btn clearBtn"
                                                        onClick={() => this.clearFilter('market')}
                                                    >
                                                        {i18n.t('Clear')}
                                                    </button>
                                                    <button
                                                        className="hmcc-drop-btn applyBtn"
                                                        onClick={() => this.applyFilter('market')}
                                                    >
                                                        {i18n.t('Apply')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="dropdown" id="dropdown3">
                                            <button
                                                className={
                                                    this.state.openedDropdown == "deliveryLocation"
                                                        ? "hmcc-drop-btn map-filter map-filter-appliedFilters"
                                                        : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg"
                                                }
                                                onClick={() => this.toggleDropdown("deliveryLocation")}
                                            >
                                                <div className={this.state.openedDropdown != "deliveryLocation" && this.state.filterObject.deliveryLocation.length > 0 ? 'FilterType mt-2' : ''}>
                                                    {this.props.app.filterObject.deliveryLocation.length > 0
                                                        ? `${"Delivery Loc. (" + this.props.app.filterObject.deliveryLocation.length + ")"}`
                                                        : i18n.t("Delivery Loc.")}{" "}</div>
                                                <img className={this.state.openedDropdown != "deliveryLocation" && this.state.filterObject.deliveryLocation.length > 0 ? 'mt-2' : ''} src={require("../../Images/drop-down-arrow.png")} />{" "}
                                                <div hidden={this.state.openedDropdown === "deliveryLocation" || !this.state.filterObject.deliveryLocation.length > 0} className='row m-0 mt-2'>
                                                    {this.state.filterObject.deliveryLocation.slice(0, 3).map((data, index) => {
                                                        return (
                                                            <span className='hmcc-drop-selectedItems'>{data}
                                                                <img id='deleteIcon' onClick={() => this.removeSelectedFilter("deliveryLocation", data)}

                                                                    src={require("../../Images/deleteSelected.svg")} />
                                                            </span>)
                                                    })}
                                                    {this.state.filterObject.deliveryLocation.length > 3 ? '+' + (this.state.filterObject.deliveryLocation.length - 3) : null}

                                                </div>
                                            </button>
                                            <div
                                                className="hmcc-dd-options"
                                                hidden={this.state.openedDropdown == "deliveryLocation" ? this.state.hideDeliveryLocDiv : true}
                                            >
                                                <div
                                                    id="searchbox2"
                                                    className="hmcc-searchbox"
                                                    hidden={
                                                        this.state.openedDropdown == "deliveryLocation" ? this.state.hideDeliveryLocDiv : true
                                                    }
                                                >
                                                    {this.state.openedDropdown == "deliveryLocation" ? (
                                                        <input
                                                            type="text"
                                                            className="checkbox-dropdown-items"
                                                            placeholder={i18n.t('Search') + '..'}
                                                            id="myInput2"
                                                            value={this.state.deliveryLocationSearchedText}
                                                            onChange={(e) => this.searchFunction("deliveryLocDropdown",e.target.value)}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div
                                                    id="myDropdown2"
                                                    className={
                                                        this.state.openedDropdown == "deliveryLocation"
                                                            ? "dropdown-content show"
                                                            : "dropdown-content"
                                                    }
                                                >
                                                    {deliveryLocationDropDownList}
                                                </div>
                                                <div id="myDropdownFooter" className='hmcc-dd-options-footer'>
                                                    <button
                                                        className="hmcc-drop-btn clearBtn"
                                                        onClick={() => this.clearFilter('deliveryLocation')}
                                                    >
                                                        {i18n.t('Clear')}
                                                    </button>
                                                    <button
                                                        className="hmcc-drop-btn applyBtn"
                                                        onClick={() => this.applyFilter('deliveryLocation')}
                                                    >
                                                        {i18n.t('Apply')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                         {/* Hidden this filter */}
                                        {/*  <div className="dropdown" id="dropdown4">
                                            <button
                                                className={
                                                    this.state.openedDropdown == "assetType"
                                                        ? "hmcc-drop-btn map-filter map-filter-appliedFilters"
                                                        : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg"
                                                }
                                                onClick={() => this.toggleDropdown("assetType")}
                                            >
                                                <div className={this.state.openedDropdown != "assetType" && this.state.filterObject.assetType.length > 0 ? 'FilterType mt-2' : ''}>
                                                    {this.props.app.filterObject.assetType.length > 0
                                                        ? `${"Asset Type (" + this.props.app.filterObject.assetType.length + ")"}`
                                                        : i18n.t("Asset Type")}{" "}</div>
                                                <img className={this.state.openedDropdown != "assetType" && this.state.filterObject.assetType.length > 0 ? 'mt-2' : ''} src={require("../../Images/drop-down-arrow.png")} />{" "}

                                                <div hidden={this.state.openedDropdown === "assetType" || !this.state.filterObject.assetType.length > 0} className='row m-0 mt-2'>
                                                    {this.state.selectedAssetsTypesLabel.slice(0, 3).map((data, index) => {
                                                        return (
                                                            <span className='hmcc-drop-selectedItems'>{data.label}
                                                                <img id='deleteIcon' onClick={() => this.removeSelectedFilter("assetType", data.value)}

                                                                    src={require("../../Images/deleteSelected.svg")} />
                                                            </span>)
                                                    })}
                                                    {this.state.filterObject.assetType.length > 3 ? '+' + (this.state.filterObject.assetType.length - 3) : null}

                                                </div>
                                            </button>
                                            <div
                                                className="hmcc-dd-options"
                                                hidden={this.state.openedDropdown == "assetType" ? this.state.hideAssetTypeDiv : true}
                                            >
                                                <div
                                                    id="searchbox3"
                                                    className="hmcc-searchbox"
                                                    hidden={this.state.openedDropdown == "assetType" ? this.state.hideAssetTypeDiv : true}
                                                >
                                                    {this.state.openedDropdown == "assetType" ? (
                                                        <input
                                                            type="text"
                                                            className="checkbox-dropdown-items"
                                                            placeholder={i18n.t('Search') + '..'}
                                                            id="myInput3"
                                                            value={this.state.assetTypeSearchedText}
                                                            onChange={(e) => this.searchFunction("assetTypeDropdown",e.target.value)}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div
                                                    id="myDropdown3"
                                                    className={
                                                        this.state.openedDropdown == "assetType" ? "dropdown-content show" : "dropdown-content"
                                                    }
                                                >
                                                    {assetTypeDropDownList}
                                                </div>
                                                <div id="myDropdownFooter" className='hmcc-dd-options-footer'>
                                                    <button
                                                        className="hmcc-drop-btn clearBtn"
                                                        onClick={() => this.clearFilter('assetType')}
                                                    >
                                                        {i18n.t('Clear')}
                                                    </button>
                                                    <button
                                                        className="hmcc-drop-btn applyBtn"
                                                        onClick={() => this.applyFilter('assetType')}
                                                    >
                                                        {i18n.t('Apply')}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        */}

                                        <div className="dropdown" id="dropdown6">
                                            <button onClick="myFunctionclose()" className="hmcc-drop-btn-close">
                                                CLOSE
                                            </button>
                                        </div>
                                        <div className="row hmcc-new-drop-btn-row ">
                                            <div className="hmcc-new-drop-btn"
                                            >
                                                <button
                                                    className="hmcc-drop-btn clearBtn"
                                                    onClick={() => this.clearMainFilters()}
                                                >
                                                    {i18n.t('Clear')}
                                                </button>
                                            </div>

                                            <div className="hmcc-new-drop-btn">
                                                <button
                                                    className="hmcc-drop-btn applyBtn"
                                                    onClick={() => this.applyMainFilters()}
                                                >
                                                    {i18n.t('Apply')}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="remove-extraspace"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  Drop down selection End */}
                    </div>


                    <div className="container mobile-filter">
                        <div className="row">
                            <div className="col-md-12 ">
                                <button
                                    onclick="myFunctionclose()"
                                    className="hmcc-drop-btn text-center w-100 my-2"
                                >
                                    <h6>Filters</h6>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                {/*  Map code Here */}
                <section className="world-map" style={{ height: `calc(100vh - ${this.props.app.showMenu ? '200px' : '158px'})` }}>
                    <div className="worldMapSvg">
                        <ComposableMap fill="#1B5EF6" viewBox="0 50 1400 550" className='reactsimplemaps'>
                            <Geographies geography={geoUrl}>
                                {({ geographies }) =>
                                    geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />)
                                }
                            </Geographies>
                            {this.state.markers.map(item => (
                                <Marker key={item.name} coordinates={item.latLng}>
                                    <circle className='mapMarkers' r={4} fill="white"></circle>
                                    {(() => {
                                        switch (item.name) {
                                            case 'India':
                                                return (
                                                    <text textAnchor="middle" y={20} fontSize={'0.6rem'} style={{ fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold' }} fill="white" >
                                                        {i18n.t(item.name)}
                                                    </text>
                                                )
                                            case 'Europe':
                                                return (
                                                    <text textAnchor="middle" y={20} fontSize={'0.6rem'} style={{ fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold' }} fill="white" >
                                                        {i18n.t(item.name)}
                                                    </text>
                                                )
                                            case 'China/Mainland':
                                                return (
                                                    <text textAnchor="middle" y={20} fontSize={'0.6rem'} style={{ fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold' }} fill="white" >
                                                        {i18n.t('China Mainland')}
                                                    </text>
                                                )
                                            case 'Latin America':
                                                return (
                                                    <text textAnchor="middle" y={20} fontSize={'0.6rem'} style={{ fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold' }} fill="white" >
                                                        {i18n.t(item.name)}
                                                    </text>
                                                )
                                            case 'North America':
                                                return (
                                                    <text textAnchor="middle" y={20} fontSize={'0.6rem'} style={{ fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold' }} fill="white" >
                                                        {i18n.t(item.name)}
                                                    </text>
                                                )
                                            case 'Other Regions':
                                                return (
                                                    <text textAnchor="middle" y={20} fontSize={'0.6rem'} style={{ fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold' }} fill="white" >
                                                        {i18n.t(item.name)}
                                                    </text>
                                                )
                                            case 'Philippines':
                                                return (
                                                    <text textAnchor="middle" y={20} fontSize={'0.6rem'} style={{ fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold' }} fill="white" >
                                                        {i18n.t(item.name)}
                                                    </text>
                                                )
                                            default:
                                                return (
                                                    <div></div>
                                                )
                                        }

                                    })()}
                                    {(() => {
                                        var lbl = ''
                                        switch (item.name) {
                                            case 'India': {
                                                lbl = <text textAnchor="middle" y={35} fontSize={10} fill="white"><tspan style={{ fill: '#9EFFF4', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"H: " + item.HumanCount}</tspan> | <tspan style={{ fill: '#C9FF6B', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"M: " + item.MachineCount}  </tspan> </text>
                                            }
                                                break;
                                            case 'Europe': {
                                                lbl = <text textAnchor="middle" y={35} fontSize={10} fill="white"> <tspan style={{ fill: '#9EFFF4', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"H: " + item.HumanCount}</tspan> | <tspan style={{ fill: '#C9FF6B', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"M: " + item.MachineCount}  </tspan> </text>
                                            }
                                                break;
                                            case 'China/Mainland': {
                                                lbl = <text textAnchor="middle" y={35} fontSize={10} fill="white"><tspan style={{ fill: '#9EFFF4', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}>{"H: " + item.HumanCount}</tspan> | <tspan style={{ fill: '#C9FF6B', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"M: " + item.MachineCount}  </tspan> </text>
                                            }
                                                break;
                                            case 'Latin America': {
                                                lbl = <text textAnchor="middle" y={35} fontSize={10} fill="white"><tspan style={{ fill: '#9EFFF4', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}>{"H: " + item.HumanCount}</tspan> | <tspan style={{ fill: '#C9FF6B', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"M: " + item.MachineCount}  </tspan> </text>
                                            }
                                                break;
                                            case 'North America': {
                                                lbl = <text textAnchor="middle" y={35} fontSize={10} fill="white"><tspan style={{ fill: '#9EFFF4', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}>{"H: " + item.HumanCount}</tspan> | <tspan style={{ fill: '#C9FF6B', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"M: " + item.MachineCount}  </tspan> </text>
                                            }
                                                break;
                                            case 'Other Regions': {
                                                lbl = <text textAnchor="middle" y={35} fontSize={10} fill="white"><tspan style={{ fill: '#9EFFF4', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}>{"H: " + item.HumanCount}</tspan> | <tspan style={{ fill: '#C9FF6B', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"M: " + item.MachineCount}  </tspan> </text>
                                            }
                                                break;
                                            case 'Philippines': {
                                                lbl = <text textAnchor="middle" y={35} fontSize={10} fill="white"><tspan style={{ fill: '#9EFFF4', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}>{"H: " + item.HumanCount}</tspan> | <tspan style={{ fill: '#C9FF6B', fontSize: '0.7rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', fontWeight: 'bold', textShadow: '0 0 1px black, 0 0 1px black' }}> {"M: " + item.MachineCount}  </tspan> </text>
                                            }
                                                break;
                                            default:
                                                break;
                                        }
                                        return lbl;

                                    })()}

                                </Marker>


                            ))}
                        </ComposableMap>

                    </div>

                </section>

                {/*  Map code End Here */}

                {/*  HUMAN + MACHINE End */}
                <section className="main-hmcc-footer" style={{ position: "fixed" }}>
                    {/* Human + machine Footer Start */}
                    <section className="hmcc-footer">
                        <div className="container transparent-bg-bottom ">
                            <div className="row text-center text-white">
                                <DateTimeClock langCode={this.props.app.languageCode} />
                                <div className="col-sm-6 ">
                                    <div className="center-bottons">

                                        <div className="toggle-positions">
                                            <div className='toggletext'>{i18n.t('Human')}</div>
                                            <div className="toggle">
                                                <input
                                                    checked={this.state.showHuman}
                                                    onClick={() => this.handleHumanMachineCount("human")}
                                                    type="checkbox"
                                                    className="check"
                                                />
                                                <div className="b switch">
                                                    <div>
                                                        <img src={require("../../Images/human_switch.svg")} />
                                                    </div>
                                                    <small className="couting-text">
                                                        {" "}
                                                        {this.state.humans["Human+Machine"]}
                                                    </small>
                                                </div>
                                                <div className="b track">
                                                    <p> </p>
                                                </div>
                                            </div>
                                            <div className='toggletext toggletext2'>{i18n.t('Machine')}</div>
                                            <div className="toggle toggle2">
                                                <input
                                                    checked={this.state.showMachine}
                                                    onClick={() =>
                                                        this.handleHumanMachineCount("machine")
                                                    }
                                                    type="checkbox"
                                                    className="check"
                                                />
                                                <div className="b switch switch2">
                                                    <div>
                                                        <img src={require("../../Images/bot_switch.svg")} />
                                                    </div>
                                                    <small className="couting-text">
                                                        {" "}
                                                        {this.state.machines["Human+Machine"]}
                                                    </small>
                                                </div>
                                                <div className="b track">
                                                    <p> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 align-self-center pt-3">
                                    <img
                                        style={{visibility:"hidden"}} 
                                        src={require("../../Images/logo.png")}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Human + machine Footer End */}

                    <section className="hmcc-footer2 text-center">
                        <h6 className="text-white">{i18n.t(this.props.config[1].displayName)}</h6>
                        <div className="d-flex">
                            <div className="box1">
                                <h4>
                                    <img src={require("../../Images/m-button-01.png")} />
                                    {this.state.humans["Human+Machine"]} <small>Off</small>{" "}
                                </h4>
                            </div>
                            <div className="box2 box2-active">
                                <h4>
                                    <img src={require("../../Images/m-button-02.png")} />
                                    {this.state.machines["Human+Machine"]} <small>On</small>{" "}
                                </h4>
                            </div>
                        </div>
                    </section>
                </section>
              
             {/* { this.props.showAnnouncements &&  
               ((this.props.userDetails.featureIds) || this.props.userDetails.dashboardRole == ADMIN) && (<Modal show={this.props.showHMtab} onHide={handleAnnouncementClose} backdrop="static"
                    dialogClassName="announcement-modal"
                    className="background-blur"
                    aria-labelledby="example-custom-modal-styling-title"
                    >
                <Modal.Header
                //closeButton={true}
                className="dashboard-modal-header"
            ></Modal.Header>
                <Modal.Body>
                <div className="row mb-3">
                            <div className="col-sm-6 graphikmedium notificationlabel">{"Announcements"}</div>
                            <div className="col-sm-6">
                              <a className="announcementCloseBtnAncr" onClick={handleAnnouncementClose}>
                                <img className="announcementCloseBtn" src={require("../../Images/drafting-compass Copy.svg")} />
                              </a>
                            </div>
                </div>
                <div className="announcementCntnrPopupDiv graphikmedium">

                    {this.props.announcements.map((item) => {
                                      return (<div className="row mb-2">
                                        <div className="col-sm-12">
                                          <div className="announcementDescCntnr">
                                            <TextareaAutosize id='textareaHTML' disabled className="announcementDesc mb-0">{item.rawDescription}</TextareaAutosize>
                                            <span className="announcementDate">{dateFormat(item.validFrom, "dd mmm yyyy")}</span>
                                          </div>
                                        </div>
                                      </div>)
                                    })} 
                    </div>
                <Form className="row announcementPopupFooter mr-2 float-right">
                <Form.Group className="formCheck">
                <Form.Check
                  type="checkbox"
                  style={{color:"#ffffffba", marginTop:"6px"}}
                  className="checkboxAlignment graphikregular"
                  checked={this.state.allowAnnouncementAutoPopup}
                  onClick={(e) =>
                    this.handleNotificationChange(e.target.checked)
                  }
                  label="Do not show this again"
                />
              </Form.Group>
            </Form>
                </Modal.Body>
                </Modal>)} */}


                { this.props.showProfile &&  
               ((this.props.userDetails.featureIds 
                             ) || this.props.userDetails.dashboardRole == ADMIN) && (<Modal show={this.props.showHMtab} onHide={handleAnnouncementCloseProfile} backdrop="static"
                    dialogClassName="announcement-modal"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="background-blur"
                    >
                <Modal.Header
                // closeButton={true}
                className="dashboard-modal-header"
            ></Modal.Header>
                <Modal.Body>
                <div className="row mb-3">
                            <div className="col-sm-6 graphikmedium notificationlabel">{"Announcements"}</div>
                            <div className="col-sm-6">
                              <a className="announcementCloseBtnAncr" onClick={handleAnnouncementClose}>
                                <img className="announcementCloseBtn" src={require("../../Images/drafting-compass Copy.svg")} />
                              </a>
                            </div>
                </div>
                <div className="announcementCntnrPopupDiv graphikmedium">

                    {this.props.announcements.map((item) => {
                                      return (<div className="row mb-2">
                                        <div className="col-sm-12">
                                          <div className="announcementDescCntnr">
                                          <TextareaAutosize id='textareaHTML' disabled className="announcementDesc mb-0">{item.rawDescription}</TextareaAutosize>
                                            <span className="announcementDate">{dateFormat(item.validFrom, "dd mmm yyyy")}</span>
                                          </div>
                                        </div>
                                      </div>)
                                    })} 
                    </div>

                <Form className="row announcementPopupFooter mr-2 float-right">
                <Form.Group className="formCheck">
                <Form.Check
                  type="checkbox"
                  style={{color:"#ffffffba" , marginTop:"6px"}}
                  className="checkboxAlignment graphikregular"
                  checked={this.state.allowAnnouncementAutoPopup}
                  onClick={(e) =>
                    this.handleNotificationChange(e.target.checked)
                  }
                  label="Do not show this again"
                />
              </Form.Group>
            </Form>
                </Modal.Body>
                </Modal>)}
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.appReducer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggleMenuDisplay: (boolean) => {
            dispatch(toggleMenuDisplay(boolean));
        },
        setFilters: (obj) => {
            dispatch(setFilters(obj));
        },
        setClients: (arr) => {
            dispatch(setClients(arr));
        },
        setClientOptions: (arr) => {
            dispatch(setClientOptions(arr));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HumanAndMachine);
