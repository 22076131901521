export const monthInOpened = [
    {
    
        name:"Years",
        data:[
            {
                label:"This Year",
                level:"years",
                value:{
                    count:1,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"Last Year",
                level:"years",
                value:{
                    count: 1,
                    offset: 1
                },
                interval:"last"
            },
            {
                label:"Last 2 Years",
                level:"years",
                value:{
                    count: 2,
                    offset: 1
                },
                interval:"last"
            },
            {
                label:"2 Years Ago",
                level:"years",
                value:{
                    count: 1,
                    offset: 2
                },
                interval:"last"
            },
            {
                label:"This & Last Year",
                level:"years",
                value:{
                    count: 2,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"This & Last 2 years",
                level:"years",
                value:{
                    count: 3,
                    offset: 0
                },
                interval:"last"
            }
        ]
    
},
    {
        name:"Months",
        data:[
            {
                label:"This Month",
                level:"months",
                value:{
                    count: 1,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"Last Month",
                level:"months",
                value:{
                    count: 1,
                    offset: 1
                },
                interval:"last"
            },
            {
                label:"Last 2 Months",
                level:"months",
                value:{
                    count: 2,
                    offset: 1
                },
                interval:"last"
            },
            {
                label:"3 Months Ago",
                level:"months",
                value:{
                    count: 1,
                    offset: 3
                },
                interval:"last"
            },
            {
                label:"This & Last Month",
                level:"months",
                value:{
                    count: 2,
                    offset: 0
                },
                interval:"last"
            }
        ]
    },
    {
        name:"Days",
        data:[
            {
                label:"Today",
                level:"days",
                value:{
                    count: 1,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"Yesterday",
                level:"days",
                value:{
                    count: 1,
                    offset: 1
                },
                interval:"last"
            },
            {
                label:"Last 7 Days",
                level:"days",
                value:{
                    count: 7,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"Last 30 Days",
                level:"days",
                value:{
                    count: 30,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"Last 90 Days",
                level:"days",
                value:{
                    count: 90,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"Last 180 Days",
                level:"days",
                value:{
                    count: 180,
                    offset: 0
                },
                interval:"last"
            },
            {
                label:"Last 360 Days",
                level:"days",
                value:{
                    count: 360,
                    offset: 0
                },
                interval:"last"
            }
        ]
    
}];