import React from "react";
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters } from "../../actions/AppActions";
import { Row, Col, Modal } from "react-bootstrap";
import { ModalImg } from "../../config/imgConfig";
import sisenseSettings from "../../config/Sisense";
import { ENVIRONMENT, ADMIN, SVM_URL } from "../../StringConstants";
import i18n from "i18next";
import Tabs from "@material-ui/core/Tabs";
import Connecting from "../../Connecting";
import { DashboardLandingView, SummaryWheelView, DashboardDetailedView } from 'svm-dashboard';
import "svm-dashboard/dist/assets/css/Dashboard.css";
import { msalInstance } from '../../index';
import { loginRequest } from '../../services/authProvider'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";

const noDataMessage = {
  header: "No Client Data",
  body: "Please select Program and Reporting year."
}
const guid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

const sisenseIsLoaded = () => {
  return ((typeof window.Sisense !== "undefined") && (typeof window.prism !== "undefined"));
}

let dashFlow = [
  {
    dash: "5f9ae0175fd2f53f38606dda",
    wgt: [
      { id: "5f9ae0175fd2f53f38606dde", ref: false },
      { id: "5f9ae0175fd2f53f38606ddb", ref: false },
      { id: "5f9ae0175fd2f53f38606ddc", ref: false },
      { id: "5f9ae0175fd2f53f38606ddd", ref: false },

      { id: "5f9ae0175fd2f53f38606de2", ref: false },
      { id: "5f9ae0175fd2f53f38606ddf", ref: false },
      { id: "5f9ae0175fd2f53f38606de0", ref: false },
      { id: "5f9ae0175fd2f53f38606de1", ref: false },

      { id: "5f9ae0175fd2f53f38606de7", ref: false },
      { id: "5f9ae0175fd2f53f38606de3", ref: false },
      { id: "5f9ae0175fd2f53f38606de5", ref: false },
      { id: "5f9ae0175fd2f53f38606de6", ref: false },
    ]
  },
  {
    dash: "5f719a0e3dad070ee862ce50",
    wgt: [
      { id: "5f719a0e3dad070ee862ce53", ref: false },
      { id: "5f719a0e3dad070ee862ce52", ref: false },
      { id: "5f719a0e3dad070ee862ce51", ref: false },
    ]
  },
];
class BusinessOutcome extends React.Component {
  state = {
    filterArray: [],
    filterArrayPoc:[],
    sisenseFrames: [],
    isRendered: false,
    applyFiltersLater: false,
    preWgt: [],
    loaded: false,
    show: false,
    arrowShow:false,
    active: 1,
    image: '',
    isFilter: false,
    loading: false,
    isConnected: true,// ENVIRONMENT === 'local',
    count: 0,
    currentApp: null,
    activeTab: 1,
    //SVM states
    selectedYear: '',
    selectedProgram: '',
    startButton: true,
    isStart: true,
    isKnowMore: true,
    isInitialized: false,
    dashboardProgram: [],
    dashboardData: [],
    dashboardClientDetails: {},
    reportingYear: [],
    programFiscalYearRange: {
      min: 0,
      max: 0,
    },
    dbDimensionList: undefined,
    dimensionSubDimension: [],
    programID: '',
    dashboardProgramDetails: {},
    dashboardCommentary: {},
    selectedClient: {},
    reportingYr:2022,
    maximizeSVMDashboard:false
  }
  applyDbFilter = (db) => {
    const payload = [...this.state.filterArray];
    if (payload) { if (!payload[0].levels[1].filter.all) this.setState({ isFilter: true }); }
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false
    };
    if (db.id !== '5f719a0e3dad070ee862ce50')
      db.$$model.filters.update(payload[0], filterOptions)
  }

  componentWillMount() {
    let clients;
    let selectedClient={};
    if (this.props.app.filterObject.tenant.length > 0) {
      // clients = [...this.props.app.filterObject.tenant];
      clients = [...this.props.app.clients];
    } else {
      let tenants = [];
      this.props.userDetails.tenant.map((item) => {
        // tenants.push(item.pseudoName);
        tenants.push(item.tenantName);
      });
      clients = [...tenants];
    }
    let clientsFilterObj;
    if (clients.length > 0) {
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: clients.map((a) => a.toUpperCase()),
      }
    } else {
      clientsFilterObj = {
        all: true,
      }
    }
    //svm client object
  // if(this.props.app.filterObject.tenantId.length == 0 && this.props.app.clientOptions.length>0){
  //   // if(this.props.app.clientOptions.some(item=> item.isDemo == true)){
  //     selectedClient = {
  //       value: this.props.app.clientOptions[0].globalSVMClientId,
  //       // text: this.props.app.clientOptions[0].tenantName,
  //       // label: this.props.app.clientOptions[0].tenantName,
  //       text: 'Force 10',
  //       label: 'Force 10',
  //     }
  //   // }else{
  //   //   this.setState({activeTab:1})
  //   // }
  // }
  // else
   if(this.props.app.clientOptions.length>0){
    // if((this.props.app.clientOptions.find(item=> item.tenantID == this.props.app.filterObject.tenantId[0])).svmClientID != 0){
      selectedClient = {
        value: this.props.app.clientOptions[0].globalSVMClientId,
        // text: this.props.app.clientOptions[0].tenantName,
        // label: this.props.app.clientOptions[0].tenantName,
        text: 'Force 10',
        label: 'Force 10',
      }
    // }else{
    //   this.setState({activeTab:1})
    // }
  }

   
    this.setState({
      selectedClient,
      filterArray: [
        {

          levels: [
            {
              dim: "[Client.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Demo"],
              },
            },
            {
              dim: "[Client.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
      filterArrayPoc:[{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": clientsFilterObj } }]
    });
  }

  componentDidMount() {
    let dashBoard1 = this.props.config.find(element => element.position == 1);////Business Outcomes
    let dashBoard2 = this.props.config.find(element => element.position == 2);//Powered by
    if (ENVIRONMENT !== 'local' && this.props.userDetails.isSisenseEnabled) {
      // const isLoaded = sisenseIsLoaded();
      // if(!isLoaded){
      //   this.ConnectJS();
      // }
      if (!this.state.loaded && this.state.isConnected) this.startFlow(this.props);  //Uncomment this before building - LOCAL

    }
  }
  handleMaxMin = () => {
    this.setState({ maximizeSVMDashboard: !this.state.maximizeSVMDashboard })
  }
  loadSisensejs = () => {
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
      let count = this.state.count + 1;
      this.setState({ count })
      if (count < 3) {
        setTimeout(this.ConnectJS(), 5000);
      }
      else {
        console.log("Error- while connecting to sisenseJS server");
      }
    }
    else {
      const Sisense = window.Sisense;
      Sisense.connect(sisenseSettings.server).then(app => {

        this.setState({
          currentApp: app,
          isConnected: true
        }, () => this.startFlow(this.props));
      });
    }
  }
  ConnectJS = () => {
    this.setState({
      isSisenseConnectCalled: true
    })
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
      try {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = sisenseSettings.server + '/js/sisense.js?v=' + guid();
        script.async = true;
        script.onload = this.loadSisensejs;
        document.body.appendChild(script);
      } catch (er) {
        this.setState({ count: 3, isConnected: true })
        console.log("errorCatch", er);
      }

    }
  }
  applyFilters = (filters) => {
    this.state.sisenseFrames.map(frame => {
      frame.dashboard.applyFilters(filters).then(function (result) {
        console.log("filter applied")
      });
    })
    this.setState({ applyFiltersLater: false });

  }
  componentWillReceiveProps(nextProps) {
    if (this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant) {

      //svm client object
      let selectedClient={};
      // if(nextProps.app.filterObject.tenantId.length == 0 && nextProps.app.clientOptions.length>0){
      //   // if(nextProps.app.clientOptions.some(item=> item.isDemo == true)){
      //     selectedClient = {
      //       value: nextProps.app.clientOptions[0].globalSVMClientId,
      //       // text: nextProps.app.clientOptions[0].tenantName,
      //       // label: nextProps.app.clientOptions[0].tenantName,
      //       text: 'Force 10',
      //       label: 'Force 10',
      //     }
      //   // }else{
      //   //   this.setState({activeTab:1})
      //   // }
      // }else 
      if(nextProps.app.clientOptions.length>0){
        // if((nextProps.app.clientOptions.find(item=> item.tenantID == nextProps.app.filterObject.tenantId[0])).svmClientID != 0){
          selectedClient = {
            value: nextProps.app.clientOptions[0].globalSVMClientId,
            // text: nextProps.app.clientOptions[0].tenantName,
            // label: nextProps.app.clientOptions[0].tenantName,
            text: 'Force 10',
            label: 'Force 10',
          }
        // }else{
        //   this.setState({activeTab:1})
        // }
      }
      let filterArray = [];
      let filterArrayPoc = [];
      if (nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length == 0) {
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Demo"],
                },
              },
              {
                "title": "Client",
                "dim": "[Client.Client]",
                "datatype": "text",
                "filter": {
                  "all": true
                }
              }
            ],
          }
        ];

      filterArrayPoc = [{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": {
        "all": true
      } } }]


      } else if (nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length != 0) {

        let tenants = [];
        this.props.userDetails.tenant.map((item) => {
          // tenants.push(item.pseudoName);
          tenants.push(item.tenantName);
        });
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Demo"],
                },
              },
              {
                "dim": "[Client.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": tenants.map(a => a.toUpperCase()) },
              },
            ],
          }
        ];

        filterArrayPoc = [{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": { "explicit": true, "multiSelection": true, "members": tenants.map(a => a+"") } } }]
      } else {
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Demo"],
                },
              },
              {
                "dim": "[Client.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": nextProps.app.clients.map(a => a.toUpperCase()) },
              },
            ],
          }
        ];
        filterArrayPoc = [{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": { "explicit": true, "multiSelection": true, "members": nextProps.app.filterObject.tenantId.map(a => a+"") } } }]

      }
      if((this.state.selectedClient.value && selectedClient.value) && (this.state.selectedClient.value == selectedClient.value)){
        this.setState({ filterArray, filterArrayPoc});
    }else{
      this.clearDashboard();
      this.setState({ filterArray, filterArrayPoc, selectedClient,  
      selectedYear: '',
      selectedProgram: '',
      startButton: true,
      isStart: true,
      isKnowMore: true,
      isInitialized: false,
      dashboardProgram: [],
      dashboardData: [],
      dashboardClientDetails: {},
      reportingYear: [],
      programFiscalYearRange: {
        min: 0,
        max: 0,
      },
      dbDimensionList: undefined,
      dimensionSubDimension: [],
      programID: '',
      dashboardProgramDetails: {},
      dashboardCommentary: {},
    });
  }
      this.startFlow(nextProps);
    }
    if (nextProps.isConnected !== this.props.isConnected) {
      if (nextProps.isConnected && ENVIRONMENT !== 'local' && this.props.userDetails.isSisenseEnabled) {
        let dashBoard1 = this.props.config.find(element => element.position == 1);////Business Outcomes
        let dashBoard2 = this.props.config.find(element => element.position == 2);//Powered by
        this.startFlow(nextProps);  //Uncomment this before building - LOCAL
      }
    }
  }

  startFlow = (nextProps) => {
    if (this.props.currentApp) {
      this.setState({ loaded: true, loading: true });
      dashFlow.forEach(d => {
        this.props.currentApp.dashboards.load(d.dash).then(dash => {
          this.setState({ loading: false });
          this.applyDbFilter(dash);
          d.wgt.forEach(w => {
            const div = document.getElementById(w.id);
            div.innerHTML = "";
            const wgt = dash.widgets.get(w.id);
            wgt.container = div;
            wgt.refresh();
            const ww = this.state.preWgt;
            ww[w.id] = wgt;
            this.setState({ preWgt: ww });
          });
        });
      });
      setTimeout(this.setRefInterval, 10000);
    } else if(nextProps.currentApp) {
      this.setState({ loaded: true, loading: true });
      dashFlow.forEach(d => {
        nextProps.currentApp.dashboards.load(d.dash).then(dash => {
          this.setState({ loading: false });
          this.applyDbFilter(dash);
          d.wgt.forEach(w => {
            const div = document.getElementById(w.id);
            div.innerHTML = "";
            const wgt = dash.widgets.get(w.id);
            wgt.container = div;
            wgt.refresh();
            const ww = this.state.preWgt;
            ww[w.id] = wgt;
            this.setState({ preWgt: ww });
          });
        });
      });
      setTimeout(this.setRefInterval, 10000);
    }

  };

  setRefInterval = () => {
    dashFlow.forEach(d => {
      d.wgt.forEach(w => {
        if (w.ref) {
          const wgt = this.state.preWgt[w.id];
          setInterval(() => {
            if (wgt) wgt.$$model.refresh();
          }, 20000);
        }
      })
    })
  }
  loadDashBoard = (dashId) => {

    const app = this.props.currentApp;

    if (app && app.dashboards && dashId) {
        app.dashboards.load(dashId).then(data => {

            this.afterLoadDash(data, dashId);

        });

    }

}

  createRow = (i) => {
    const row = document.createElement('div');
    row.className = "row";
    row.style.marginLeft = "0px";
    row.style.marginRight = "0px";
    row.id = "row" + i;
    return row;
  }
  createLayout = (i, el, width, dashId) => {
    const headerDiv = document.createElement("div");
    headerDiv.id = "head" + el.widgetid;
    headerDiv.style.padding = "5px 20px";
    headerDiv.style.color = "white";
    headerDiv.style.borderTopRightRadius = '10px';
    headerDiv.style.borderTopLeftRadius = '10px';
    headerDiv.style.backgroundColor = "#0242C6";

    const height = el.height === "" || el.height === 512 ? "100%" : el.height.toString().replace("px", "") + "px";
    const div = document.createElement('div');
    div.id = el.widgetid;
    div.style.height = height;
    div.style.backgroundColor = "#2291FF";

    const pdiv = document.createElement('div');
    pdiv.className = "layout row" + i
    pdiv.style.width = "calc(" + width + "% - 4px)";
    pdiv.style.margin = "2px";

    pdiv.appendChild(headerDiv);
    pdiv.appendChild(div);

    return pdiv;
  }
  

  afterLoadDash = (db, divId) => {
    this.applyDbFilter(db);
    let widgets = [];
    const layout = db.$$model.layout.columns[0].cells;
    const wRoot = document.getElementById("businessModal");
    if (wRoot) wRoot.innerHTML = "";
    let i = 0;
    layout.forEach(col => {
      i++;
      const row = this.createRow(i);
      col.subcells.forEach(cell => {
        const width = cell.width ? cell.width : 100;
        row.appendChild(this.createLayout(i, cell.elements[0], width));
        widgets.push(cell.elements[0].widgetid);
      })
      wRoot.appendChild(row);
      if (i === layout.length) setTimeout(() => this.loadWgt(db, widgets), 1000);
    });
  };

  loadWgt = (db, widgets) => {
    widgets.forEach(wgt => {
      const div = document.getElementById(wgt)
      const oWgt = db.widgets.get(wgt);
      if (oWgt) {
        const head = document.getElementById("head" + wgt);
        if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
          head.innerHTML = oWgt.title;
        else
          if (head) head.style.display = "none";

        if (!oWgt.container)
          oWgt.container = div;
        else {
          const clone = Object.assign(oWgt);
          clone.$$container = null;
          clone.container = div;
        }
        oWgt.refresh();
        
      }

    })
  };


  reloadWgt = (wid) => {
    const div = document.getElementById(wid);
    div.innerHTML = "";
    setTimeout(() => {
      const clone = Object.assign(this.state.preWgt[wid]);
      clone.$$container = null;
      clone.refresh();
      clone.container = div;
    }, 1000);
  };

  handleClick = (e) => {
    this.setState({ active: e });
    switch (e) {
      case 1: this.reloadWgt("5f9ae0175fd2f53f38606de7"); break;
      case 2: this.reloadWgt("5f9ae0175fd2f53f38606de3"); break;
      case 3: this.reloadWgt("5f9ae0175fd2f53f38606de5"); break;
      case 4: this.reloadWgt("5f9ae0175fd2f53f38606de6"); break;
      default: break;
    }
  }
  setTab = (value) => {
    this.setState({ activeTab: value, isKnowMore:true });
  }

  onStart = () => {
    this.setState({ isStart: true });
    const programID = this.state.programID;
    this.createLog({ programID });
};

createLog = (props) => {
    /* Optional function if enableLogger set to false or not declared*/
}

onKnowMore = () => {
   this.setState({ isKnowMore: true });
};
onClickBackBtn = () => {
  this.setState({ isKnowMore: false }, () => {
      
  });
};

GetAuthObj = async() => {
  if (ENVIRONMENT !== 'local') {
    const account = msalInstance.getAllAccounts()[0];
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
//always call the acquiretokensilent first before calling any API
const response = await msalInstance.acquireTokenSilent({
  ...loginRequest,
  account: account
}).catch(err => {
  //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
  //always check if the error is something that requires auth login interaction 
  if (err instanceof InteractionRequiredAuthError) {
    return msalInstance.acquireTokenPopup({
      ...loginRequest,
      account: account
    }).catch(err => {
      console.log('Error getting popup token: ' + JSON.stringify(err))
    });
  }
});
// GetAuthObj function should return an object below
const data = {
  apiHttps: SVM_URL,//SVM_COMMON_URL,
  accessToken: response.accessToken
};
return data;
}

}

clearDashboard = async(key) => {
try {
switch (key) {
  case 'reportingYear':
  this.setState({
    dimensionSubDimension: [],
    programID: '',
    dbDimensionList: undefined,
  });
  break;
  case 'program':
  this.setState({
    dimensionSubDimension: [],
    programID: '',
    dbDimensionList: undefined,
  });
  break;
  default:
  this.setState({
    reportingYear: [],
    dashboardProgram: [],
    dimensionSubDimension: [],
    programID: '',
    dbDimensionList: undefined,
  });
  break;
}
} catch (err) {
console.log(err);
}
}


// Required Callback functions
fetchDashboardDataCallback = async (data) => {
  if (data.dashboardData.length>0) {
  // console.log("fetchDashboardDataCallback", data);
  const selectedProgram = {
    id: String(data.dashboardData[0].id),
    label: data.dashboardData[0].programName,
    value: data.dashboardData[0].programName,
  };
  const year = data.dashboardData[0].ReportingYear;
  const selectedYear = { label: [year], value: [year] };
  
  const reportingYear = [];
  let defaultYear = {}
  
  data.dashboardData.filter(x => x.id == data.dashboardData[0].id).map((elem)=>{
    reportingYear.push({ label: [elem.ReportingYear], value: [elem.ReportingYear] })
  })
  let currentYear = new Date().getFullYear();
  let isPresentYear = reportingYear.some(val=>val.label[0]==currentYear);
  if(isPresentYear){
  defaultYear = { label: [currentYear], value: [currentYear] };
  }else{
  defaultYear = {...selectedYear}
  }
  
  this.setState({
    dashboardProgram: data.dashboardProgram,
    dashboardData: data.dashboardData,
    selectedProgram,
    reportingYear:[selectedYear],
    selectedYear:defaultYear
  },
  () => {
      const reportingYear = [];
      data.dashboardData.filter(x => x.id == data.dashboardData[0].id).map((elem)=>{
          reportingYear.push({ label: [elem.ReportingYear], value: [elem.ReportingYear] })
      })
    this.setState({
      reportingYear
    });
  })
  }
  }
fetchClientDetailsCallback = async (data) => {
if (data) {
// console.log("fetchClientDetailsCallback", data);
this.setState({
  dashboardClientDetails: data.dashboardClientDetails,
  isInitialized: true,
})
}
}
onSelectProgramCallback = async (data, selProgram) => {
if (data) {
// console.log("onSelectProgramCallback", data);
this.setState({
  reportingYear: data.reportingYear
})

if (this.state.isStart) {
  /* this.props.setToasterParam({
  show: true,
  type: 'success',
  message: 'Program data loaded successfully.',
  }); */
  
  let isPresent = false;
  if(this.state.selectedYear){
    isPresent = data.reportingYear.some(val=> this.state.selectedYear.value[0]==val.value[0])
  }
  this.setState({
  selectedProgram: selProgram,
  selectedYear:isPresent?this.state.selectedYear:data.reportingYear[0]
  })
} else {
  this.setState({
  selectedYear: '',
  selectedProgram: selProgram,
  });
}
}
}

setSelectedReportingYearCallback = async (e) => {
if (!this.state.startButton) {
this.clearDashboard('reportingYear');
}
if (this.state.selectedProgram === '') {
this.setState({
  selectedYear: e,
  isStart: false,
});
} else {
this.setState(
  {
  selectedYear: e,
  },
  () => {
  //  if (this.state.isStart) {
    // this.props.setToasterParam({
    // show: true,
    // type: 'success',
    // message: 'Program data loaded successfully.',
    // });
  // } 
  }
);
}
}
getDashboardDimensionsCallback = async (data) => {
if (data) {
// console.log("getDashboardDimensionsCallback", data);
this.setState({
  programFiscalYearRange: data.programFiscalYearRange,
  dbDimensionList: data.dbDimensionList,
  dimensionSubDimension: data.dimensionSubDimension,
  programID: data.programID,
  dashboardProgramDetails: data.dashboardProgramDetails
})
}
}
setCommentaryRequiredDataCallback = async (data) => {
 if (data) {
// console.log("setCommentaryRequiredDataCallback", data);
this.setState({
  dashboardCommentary: data.dashboardCommentary,
})
 }
}
onClickBackBtn = () => {
  this.setState({ isKnowMore: false }, () => {
    // this.props.panelOnClick(null, false);
  });
};


  render() {
    const showModal = () => {
      return (
        <Modal show={this.state.show} centered size="lg" onHide={handleClose} className="business_modal-holder">
          <Modal.Body className="BO-modal">
            <div>
            <img src={this.state.image} alt="modal" style={{ width: "100%" }} />
          </div>
          </Modal.Body>
        </Modal>
      );
    }
    const showArrowModal = () => {
      return (
        <Modal show={this.state.arrowShow} centered size="lg" onHide={handleArrowClose} className="business_modal-holder">
          <Modal.Body className="BO-modal">
            {/* <div>
            <img src={this.state.image} alt="modal" style={{ width: "100%" }} />
          </div> */}
          <div id="businessModal" className="db-container" style={{height:"inherit"}}></div>
          </Modal.Body>
        </Modal>
      );
    }

    const handleClose = () => this.setState({ show: false });
    const handleArrowClose = () => this.setState({ arrowShow: false });
    const handleArrowModal = (drillDB) => {
      // const modImg = ModalImg.filter(f => f.imageID === drillDB)[0].image64;
      this.loadDashBoard(drillDB)
      this.setState({ arrowShow: true, show:false});
    }
    const handleModal = (drillDB) => {
      const modImg = ModalImg.filter(f => f.imageID === drillDB)[0].image64;
      this.setState({ show: true, image:modImg, arrowShow:false});
    }

    return (
      <>
        {/* {this.props.isConnected ? */}
          <div data-test="businessComponent">

            <div className={this.state.loading ? "loading" : "hidden"}>
              <img
                className="loader-size"
                src={require("../../Images/WO-background.gif")}
                alt=""
              />
            </div>
            <section className=''>
            <div className='container-fluid px-4 tab-pane' >
              <div className="new-subTablist row" data-test='appStoreContainer'>
                <div className="col-md-10">
                  <nav id="appTabs" className="">
                    <div className="row " >
                      <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        style={{ cursor: "pointer" }}
                      >
                        <div className='d-inline-block'>
                          <div className="media">
                            <div
                              className={
                                this.state.activeTab == 1
                                  ? "media-body newTab-text  newTab-text-active"
                                  : "media-body newTab-text"
                              }
                              onClick={() => this.setTab(1)}
                            >
                              {i18n.t("Value Delivered")}
                            </div>
                          </div>
                        </div>

                        {(this.state.selectedClient.value?(this.state.selectedClient.value!=0):false) && (<div className='d-inline-block'>
                          <div className="media">
                            <div
                              className={
                                this.state.activeTab == 2
                                  ? "media-body newTab-text  newTab-text-active"
                                  : "media-body newTab-text"
                              }
                              onClick={() => this.setTab(2)}
                            >
                              {i18n.t("360⁰ Value Meter")}
                            </div>
                          </div>
                        </div>
                        )}
                      </Tabs>
                    </div>
                  </nav>
                </div>
              </div>
              <section style={{ padding: "0", display: this.state.activeTab == 1 ? "block" : "none" }} className={(this.props.app.clients.length > 0) ? 'clientNameAdded' : ''}>

              <div className="container-fluid data-container2 operation-main-container tab4-background" style={{ position: 'relative', top: '0px', marginLeft:'0px'}}>

                <div className="inner-date2 text-white">
                  <div className="container-fluid">

                    <div className="col-md-12 col-lg-12">
                      <div className="" style={{marginBottom:"0px"}}>
                        <h5 className="dash-title">{i18n.t((this.props.config.find(element => element.position == 1)).displayName)}</h5>
                        <div
                          data-test="businessContainer"
                          id="announcementsDashboard1"
                          className="business-height"
                        >
                          <Row className={this.state.isFilter ? "hidden" : ""}>
                          <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606dde" onClick={() => this.handleClick(1)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("622f234eec88950036056755")}>&gt;</div>)}
                            </Col>
                            <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606ddb" onClick={() => this.handleClick(2)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("6231ecd5fa9bff0036ab44ee")}>&gt;</div>)}
                            </Col>
                            <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606ddc" onClick={() => this.handleClick(3)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("6231dba0fa9bff0036ab4258")}>&gt;</div>)}
                            </Col>
                            <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606ddd" onClick={() => this.handleClick(4)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("62321ef0fa9bff0036ab4919")}>&gt;</div>)}
                            </Col>
                          </Row>
                          <Row className={!this.state.isFilter ? "hidden" : ""}>
                            <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606de2" onClick={() => this.handleClick(1)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("622f234eec88950036056755")}>&gt;</div>)}
                            </Col>
                            <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606ddf" onClick={() => this.handleClick(2)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("6231ecd5fa9bff0036ab44ee")}>&gt;</div>)}
                            </Col>
                            <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606de0" onClick={() => this.handleClick(3)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("6231dba0fa9bff0036ab4258")}>&gt;</div>)}
                            </Col>
                            <Col md={3} style={{ padding: 0 }}>
                              <div id="5f9ae0175fd2f53f38606de1" onClick={() => this.handleClick(4)}></div>
                              {(<div className="anchor" onClick={() => handleArrowModal("62321ef0fa9bff0036ab4919")}>&gt;</div>)}
                            </Col>
                          </Row>

                          <Row>
                            <div className={this.state.active === 1 ? "" : "hidden"} id="5f9ae0175fd2f53f38606de7" style={{ height: "360px", width: "100%", padding: "20px" }}></div>
                            <div className={this.state.active === 2 ? "" : "hidden"} id="5f9ae0175fd2f53f38606de3" style={{ height: "360px", width: "100%", padding: "20px" }}></div>
                            <div className={this.state.active === 3 ? "" : "hidden"} id="5f9ae0175fd2f53f38606de5" style={{ height: "360px", width: "100%", padding: "20px" }}></div>
                            <div className={this.state.active === 4 ? "" : "hidden"} id="5f9ae0175fd2f53f38606de6" style={{ height: "360px", width: "100%", padding: "20px" }}></div>
                          </Row>

                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid data-container2 operation-main-container tab4-background" style={{ position: 'relative', top: '0px',  marginLeft:'0px', marginTop: '8px', display: "block"}}>
                <div className="inner-date2 text-white">
                  <div className="container-fluid">
                    <div className="col-md-12 col-lg-12">
                      <div className="" style={{marginBottom:"0px"}}>
                        <h5 className="dash-title">{i18n.t((this.props.config.find(element => element.position == 2)).displayName)}</h5>
                        <div
                          id="announcementsDashboard2"
                          className="powered-height"
                        >
                          <Row>
                            <Col><div id="5f719a0e3dad070ee862ce53"></div></Col>
                            <Col><div id="5f719a0e3dad070ee862ce52"></div></Col>
                            <Col><div id="5f719a0e3dad070ee862ce51"></div></Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* SVM dashboard */}
            <div className={`app-frame-container ${this.state.maximizeSVMDashboard ? " maximizedSVMDashboard" : ""}`} style={{ padding: "0", display: (this.state.activeTab == 2 && (this.state.selectedClient.value?(this.state.selectedClient.value!=0):false)) ? "block" : "none" }}>
            <FontAwesomeIcon className='float-right svmMaxMinIcon' style={{ cursor: "pointer" }}
                        onClick={() => this.handleMaxMin()}
                        color={"#fff"}
                        title={!this.state.maximizeSVMDashboard ? "Maximize" : "Minimize"}
                        icon={this.state.maximizeSVMDashboard ? faCompressAlt : faExpandAlt} />
                <div className="container-fluid data-container2 operation-main-container">
                  <div className="row inner-date2 text-white" style={{ overflowY: "hidden" }}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="data-sections2 dark_theme_container" style={{ width: '96%', padding: '0px' }}>
                          <div className="row">
                            <div className={`col-md-12 col-lg-12 svm-dark-theme ${this.state.maximizeSVMDashboard ? " maximizedSVMDiv" : "minimizedSVMDiv"}`}>
                              
                      {(this.state.selectedClient.value?(this.state.selectedClient.value!=0):false)
                       && (this.state.isKnowMore ? (
                          <DashboardDetailedView
                            dbDimensionList={this.state.dbDimensionList} // main data - DashboardProjectDetails
                            dimensionSubDimension={this.state.dimensionSubDimension} // main data - DashboardDimGraph
                            programID={this.state.programID}
                            dashboardCommentary={this.state.dashboardCommentary}
                            reportingYear={this.state.reportingYear}
                            programFiscalYearRange={this.state.programFiscalYearRange}
                            // UserInformation
                            // enterpriseID={this.props.enterpriseID} // default to "", Current User
                            // isSuperAdmin={this.props.isSuperAdmin} // default to false, Set to false for non-SVM
                            // userProfiles={this.props.userProfiles} // default to [], Set to empty array for non-SVM
                            // showBackButton={true} // default to false
                            // showCommentary={true} // default to false
                            noDataMessage={noDataMessage}
                            isOverrideHandleProjectDetails={false}
                            // Dropdown
                            selectedClient={this.state.selectedClient}
                            isInitialized={this.state.isInitialized}
                            isStart={this.state.isStart}
                            isKnowMore={this.state.isKnowMore}
                            dashboardClientDetails={this.state.dashboardClientDetails}
                            dashboardData={this.state.dashboardData}
                            programOptions={this.state.dashboardProgram}
                            selectedProgram={this.state.selectedProgram}
                            yearOptions={this.state.reportingYear}
                            selectedYear={this.state.selectedYear}
                            // For auto select functionality
                            // isDBAutoSelect={this.state.isDBAutoSelect}
                            // progID={this.props.progID}
                            // reportingYr={this.props.reportingYr}
                            // Actions
                            onClickBackBtn={this.onClickBackBtn}
                            // createLog={this.props.createLog} // Logger function for SVM
                            enableLogger={false} // default to false
                            getAuthObj={this.GetAuthObj}
                            // Callback
                            fetchDashboardDataCallback={this.fetchDashboardDataCallback}
                            fetchClientDetailsCallback={this.fetchClientDetailsCallback}
                            onSelectProgramCallback={this.onSelectProgramCallback}
                            setSelectedReportingYearCallback={
                              this.setSelectedReportingYearCallback
                            }
                            getDashboardDimensionsCallback={
                              this.getDashboardDimensionsCallback
                            }
                            setCommentaryRequiredDataCallback={
                              this.setCommentaryRequiredDataCallback
                            }
                            // overrideHandleProjectDetails={this.overrideHandleProjectDetails}
                          />
                        ) : (
                          <SummaryWheelView
                            dimensionSubDimension={this.state.dimensionSubDimension} // main data
                            dashboardProgramDetails={this.state.dashboardProgramDetails}
                            onKnowMore={this.onKnowMore}
                            // isGlobal={false} - default to false when no value
                            // isGlobalPreview={false} - default to false when no value
                            // displayDownloadReport={true} // - default to false
                            displayKnowMore={true} // - default to false
                            // Dropdown
                            selectedClient={this.state.selectedClient}
                            isInitialized={this.state.isInitialized}
                            isStart={this.state.isStart}
                            isKnowMore={this.state.isKnowMore}
                            dashboardClientDetails={this.state.dashboardClientDetails}
                            dashboardData={this.state.dashboardData}
                            programOptions={this.state.dashboardProgram}
                            selectedProgram={this.state.selectedProgram}
                            yearOptions={this.state.reportingYear}
                            selectedYear={this.state.selectedYear}
                            // For auto select functionality
                            // isDBAutoSelect={this.state.isDBAutoSelect}
                            // progID={this.state.progID}
                            // reportingYr={this.state.reportingYr}
                            // Actions
                            onClickBackBtn={this.onClickBackBtn}
                            createLog={this.createLog} // Logger function for SVM, Optional, not required if  enableLogger set to false or not declared
                            enableLogger={false} // default to false
                            getAuthObj={this.GetAuthObj}
                            // Callback
                            fetchDashboardDataCallback={this.fetchDashboardDataCallback}
                            fetchClientDetailsCallback={this.fetchClientDetailsCallback}
                            onSelectProgramCallback={this.onSelectProgramCallback}
                            setSelectedReportingYearCallback={
                              this.setSelectedReportingYearCallback
                            }
                            getDashboardDimensionsCallback={
                              this.getDashboardDimensionsCallback
                            }
                            setCommentaryRequiredDataCallback={
                              this.setCommentaryRequiredDataCallback
                            }
                          />
                           ))
                      } 

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </section>
            
            {showModal()}
            {showArrowModal()}
          </div> 
          {/* : <Connecting />} */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.appReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuDisplay: (boolean) => {
      dispatch(toggleMenuDisplay(boolean));
    },
    setFilters: (obj) => {
      dispatch(setFilters(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessOutcome);
