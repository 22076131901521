import React, { useState, useEffect } from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import sisenseSettings from "../../config/Sisense";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { saveAs } from 'file-saver';
import * as html2canvas from 'html2canvas';
import ImageIcon from '@material-ui/icons/Image';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "transparent"
    },
    bgColor: {
        // backgroundColor: theme.palette.primary.main,
        paddingTop: "0px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "22px"
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.text.main,
        fontSize: "12px"
    },
}))(Tooltip);
const server = sisenseSettings.server;
var prism = window.prism;

const DetailedDashboard = (props) => {
    const classes = useStyles();
    const [exportWgtDetails, setExportWgtDetails] = useState({});
    const [count, setCount] = useState(0);
    const ApplyFilter = (db, payload) => {
        const filterOptions = {
            save: true,
            refresh: true,
            unionIfSameDimensionAndSameType: false,
        };
        if (payload) {
            db.$$model.filters.clear();
            setTimeout(() => {
                db.$$model.filters.update(payload, filterOptions);
            }, 500);
            // db.$$model.filters.update(payload, filterOptions);
        }
    };


    const loadDashBoard = (dashId) => {
        const app = props.currentApp;
        if (app && app.dashboards && dashId) {

            app.dashboards.load(dashId).then(data => {
                ApplyFilter(data, props.payload);
                afterLoadDash(data, dashId);
            });
        }
    }
    const createRow = (i) => {
        const row = document.createElement('div');
        row.className = "row";
        row.id = "row" + i;
        return row;
    }

    const createLayout = (i, el, width, celSize) => {
        const headerDiv = document.createElement("div");
        headerDiv.innerText = "title";
        headerDiv.id = "head" + el.widgetid;
        headerDiv.style.padding = "5px 20px";
        headerDiv.style.color = "white";
        headerDiv.style.backgroundImage = "linear-gradient(rgb(34, 145, 255), rgb(3, 48, 198))";

        const div = document.createElement('div');
        div.id = el.widgetid;
        const hgt = typeof (el.height) === "string" ? parseInt(el.height.split("px")[0]) : el.height;
        const fulHeight = hgt > 480 ? hgt + "px" : "80vh"
        div.style.height = props.cssDash ? el.height.toString().replace("px", "") + "px" : celSize == 1 ? fulHeight : el.height === 512 ? "480px" : el.height.toString().replace("px", "") + "px";
        const pdiv = document.createElement('div');
        pdiv.className = "layout row" + i
        pdiv.style.width = "calc(" + width + "% - 4px)";
        pdiv.style.margin = "2px";

        pdiv.appendChild(headerDiv);
        pdiv.appendChild(div);

        return pdiv;
    }
    const afterLoadDash = (db, id) => {
        let widgets = [];
        const layout = db.$$model.layout.columns[0].cells
        const celSize = layout.length
        const wRoot = document.getElementById(id);
        wRoot.innerHTML = "";
        let i = 0;
        layout.forEach(col => {
            i++;
            const row = createRow(i);
            const size = col.subcells.length
            col.subcells.forEach(cell => {
                const width = size == 1 ? 100 : cell.width ? cell.width : 100
                row.appendChild(createLayout(i, cell.elements[0], width, celSize));
                widgets.push(cell.elements[0].widgetid);
            })
            wRoot.appendChild(row);
            if (i === layout.length) loadWgt(db, widgets)
        });

    }
    const loadWgt = (db, widgets) => {
        let i = 0;
        let count = widgets.length;
        setCount(count);
        widgets.forEach(wgt => {
            i++;
            const div = document.getElementById(wgt)
            const oWgt = db.widgets.get(wgt);
            if (oWgt) {
                const head = document.getElementById("head" + wgt);
                if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
                    head.innerText = oWgt.title;
                else
                    head.style.display = "none";
                if (!oWgt.container) {
                    oWgt.container = div;

                }
                else {
                    const clone = Object.assign(oWgt);
                    clone.$$container = null;
                    clone.container = div;
                }
                oWgt.on('ready', () => {
                    const head = document.getElementById("head" + wgt);
                    if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
                        head.innerText = oWgt.title;
                    else
                        head.style.display = "none";
                    if (oWgt.type === "chart/line")
                        document.getElementById(props.id).style.margin = "0 0 28vh 0";

                    if (oWgt.type === "tablewidget" || oWgt.type === "pivot2") {
                        setExportWgtDetails(oWgt);
                    }


                    db.on('filterschanged', (dd) => {

                        let id = oWgt.$$model.options.drillTarget ? oWgt.$$model.options.drillTarget.oid : null;
                        var oMod;
                        let xAxis = oWgt.metadata.panels[0].items.map((data) => {
                            if (data.jaql && data.jaql.level)
                                return data.jaql.level;
                            else
                                return data.jaql.column;
                        });
                        var pay = props.payload
                        dd.filters.$$items.forEach(filt => {
                            if (filt.jaql) {
                                if (xAxis.includes(filt.jaql.level) || xAxis.includes(filt.jaql.title)
                                    || xAxis.includes(filt.jaql.column)) {
                                    var val = "All";
                                    if (xAxis.includes(filt.jaql.level))
                                        val = new Date(filt.jaql.filter.members[0].toString().replace("+0530 (India Standard Time)", "")).toJSON();
                                    else if (xAxis.includes(filt.jaql.title) || xAxis.includes(filt.jaql.column))
                                        val = filt.jaql.filter.members[0]; oMod = val === "All" ? false : true;
                                    if (val && val !== "All") {
                                        pay = [...pay, {
                                            jaql: {
                                                title: filt.jaql.title,
                                                level: filt.jaql.level,
                                                dim: filt.jaql.dim,
                                                datatype: filt.jaql.datatype,
                                                filter: {
                                                    members: [val],
                                                }
                                            }
                                        }]
                                    }
                                }
                            }
                        })
                        if (id && oMod) {
                            if (props.cssDash) {
                                let paynew = dd.filters.$$items;
                                props.dashEvent(oWgt, paynew);
                            }
                            else {
                                props.dashEvent(oWgt, pay)
                            }
                        }
                    });

                    props.widgetReady();
                })


            }

            if (widgets.length === i) {
                db.refresh();
            }
        })
    }

    useEffect(() => {
        loadDashBoard(props.id);
    }, [props.id]);

    useEffect(() => {
        if (props.reloadDash)
            loadDashBoard(props.id);
    }, [props.reloadDash]);

    const getmetadata = (jaql) => {
        let meta = [];
        if (props.cssDash) {
            jaql.forEach((data) => {
                if (data.jaql.title === "Months in Opened At" && data.jaql.level === "months" &&
                    data.jaql.filter.members.length > 0 && !data.jaql.filter.all) {
                    let utcDate = data.jaql.filter.members[0];
                    var localDate = new Date(utcDate);
                    var date = new Date(localDate + "UTC");
                    data.jaql.filter.members[0] = date.toISOString();
                }
                meta.push(data);
            });
        }
        else {
            meta = jaql;
        }
        return meta;
    }

    const downloadData = () => {
        if (exportWgtDetails.title) {
            if (count > 1) {
                var wgt = document.getElementById(props.id);
                html2canvas(wgt).then(function (canvas) {

                    canvas.toBlob(function (blob) {
                        saveAs(blob, "Dashboard");
                    });
                })
            }
            let oWgt = exportWgtDetails;
            const getJaql = JSON.parse(window.prism.debugging.GetJaql(oWgt.$$model));
            const jaql = {
                "datasource": getJaql.datasource,
                "metadata": getmetadata(getJaql.metadata)
            }
            const queryUrl = server + '/api/datasources/Operations Human Machine/jaql';
            props.currentApp.$$http.post(queryUrl, JSON.stringify(jaql)).then((response) => {
                var csv = "data:text/csv;charset=utf-8,";
                csv += response.data.headers.join(",") + "\n";
                for (var i = 0; i < response.data.values.length; i++) {
                    var thisRow = "";
                    for (var j = 0; j < response.data.values[i].length; j++) {
                        let type = typeof (response.data.values[i][j].text) === "string";
                        let num = parseFloat(response.data.values[i][j].text)
                        let isInt = Math.floor(num) >= 0 && num % 1 !== 0;
                        var dta = isInt ? (Math.round(response.data.values[i][j].text * 100) / 100).toFixed(2) :
                            type ? response.data.values[i][j].text.replaceAll(",", " ") : response.data.values[i][j].text;
                        thisRow += j < response.data.values[i].length ? dta + "," : dta;
                    }
                    csv += i < response.data.values.length ? thisRow + "\n" : thisRow;
                }
                var ttl = oWgt.title === "" ? oWgt.id : oWgt.title
                var downloadLink = document.createElement("a");
                downloadLink.href = csv;
                downloadLink.download = ttl + ".csv";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
        }
        else {
            var wgt = document.getElementById(props.id);
            html2canvas(wgt).then(function (canvas) {

                canvas.toBlob(function (blob) {
                    saveAs(blob, "Dashboard");
                });
            })
        }
    }

    return (
        <div style={{ position: "relative" }}>

            <div className={"expBtn exprtBtn"}
                onClick={() => downloadData()}>

                <LightTooltip placement="top"
                    title={"Export"}>
                    <GetAppIcon />
                </LightTooltip>
            </div>
            <div className={"compDashboard mt-20 "} >
                <div id={props.id} className={classes.bgColor} />
            </div>
        </div>
    );

}

export default DetailedDashboard;
