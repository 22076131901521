import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { cssDashData } from "../../config/SisenseDashboards";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import DetailedDashboard from "./DetailedDashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark + " !important",
        // border: "5px solid " + theme.palette.primary.main,
        borderRadius: "12px",
        cursor: "pointer !important",
        margin:"5px !important"
    },
    main: {
        // backgroundColor: theme.palette.primary.main,
        overflowY: 'auto',
        height:"62.5vh"
    },
    modal: {
        backgroundColor: "#0058ff",
        padding: "16px",
        margin: '40px auto',
        borderRadius: '4px',
        color: theme.palette.text.main,
        width: "75%"
    },

}));

const CssDashboard = (props) => {
    const classes = useStyles();
    var pay = props.payload;

    const app = props.currentApp;
    const [openModal, setopenModal] = React.useState(false);
    const [openTable, setOpenTable] = React.useState(false);
    const [firstTabId, setFirstTabId] = React.useState("");
    const [tableId, setTableId] = React.useState("");
    const [tablePayload, setTablePayload] = React.useState("");
    const [payJqal, setPayJaql] = React.useState([]);

        /**
     * function to display business outcome widgets
     * @param {*} dashId input type should be a valid dashboard ID as string
     */
    const ApplyFilter = (db, npay) => {
        const filterOptions = {
            save: true,
            refresh: true,
            unionIfSameDimensionAndSameType: false,
        };
        db.$$model.filters.update(npay, filterOptions);
    };

    const loadDash = (dashId) => {
        if (app && app.dashboards) {
            const wgts = cssDashData[0].widgets;
            app.dashboards.load(dashId).then(data => {
                ApplyFilter(data, pay);
                wgts.forEach(wgt => {
                    const oWgtl = data.widgets.get(wgt.widgetId)
                    const div = document.getElementById(wgt.widgetId);
                    if (div) div.innerHTML = "";
                    oWgtl.container = div;
                    const head = document.getElementById("head" + wgt.widgetId);
                    if (oWgtl.title.length > 0)
                        head.innerHTML = oWgtl.title;
                    oWgtl.refresh();
                    oWgtl.on('ready', () => {
                        let val = oWgtl.queryResult[0][0];
                        if (val.Text == 0 || val.Text == "0%") {
                            document.getElementById(wgt.widgetId).style.pointerEvents = 'none';
                        }
                        else {
                            document.getElementById(wgt.widgetId).style.pointerEvents = 'unset';
                        }
                    });
                })
                // data.refresh();
                props.widgetReady();
            });
        }
    }

    useEffect(() => {
        loadDash("6188bb7e622ce30035b79d61");
    }
    , [props.operations.app.filterObject.tenant, props.reloadDash, props.payload]
    );

    const onCatClick = (cat, id) => {
        setopenModal(true);
        setFirstTabId(id);

        var newpay = {};

        if (cat === "Reopened") {
            newpay = {
                jaql:
                {
                    dim: "[Ticket.Reopened]",
                    datatype: "text",
                    title: "Reopened",
                    filter: {
                        members: ["Reopened"]
                    }
                }
            }
        }
        else if (cat === "Cancelled") {
            newpay = {
                jaql:
                {
                    dim: "[Ticket.Status]",
                    datatype: "text",
                    title: "Status",
                    filter: {
                        members: ["Cancelled"]
                    }
                }
            }
        }

        setPayJaql(newpay)
    }
    const dashEvent = (currentDash, payload) => {
        let id = currentDash.$$model.options.drillTarget ? currentDash.$$model.options.drillTarget.oid : null;
        if (id !== null) {

            var tabPayload = payload
            if (payJqal.jaql) {
                tabPayload = [...payload, payJqal];
            }
            setTablePayload(tabPayload);
            setOpenTable(true);
            setTableId(id);
        }
    }
    return (
        <React.Fragment>
                    <Modal
                        open={openModal}
                        onClose={() => setopenModal(false)}
                    >
                        <div className={classes.modal + " modalDiv"} style={{ width: "100%", margin: "10px auto", color:"white"}}>
                    <div className="disp-flex align-right mt-10 mb-10">
                        <div className="disp-flex align-right"
                            onClick={() => { setopenModal(false);}}>
                            <CloseIcon className="cursor" />
                        </div>
                    </div>

                    <DetailedDashboard
                        id={firstTabId} cssDash={true} currentApp={props.currentApp} payload={props.payload} dashEvent={(currentDash, payload) => dashEvent(currentDash, payload)}
                     />
                    </div>
                    </Modal>    
                    <Modal
                        open={openTable}
                        onClose={() => setOpenTable(false)}
                    >
                        <div className={classes.modal + " modalDiv"} style={{ width: "100%", margin: "10px auto", color:"white"}}>
                    <div className="disp-flex align-right mt-10 mb-10">
                        <div className="disp-flex align-right"
                            onClick={() => { setOpenTable(false);}}>
                            <CloseIcon className="cursor" />
                        </div>
                    </div>

                    <DetailedDashboard
                        id={tableId} cssDash={true} currentApp={props.currentApp} payload={tablePayload} dashEvent={(currentDash, payload) => dashEvent(currentDash, payload)}
                     />
                    </div>
                    </Modal> 
                

            <div className={classes.main + " indicatorMain"} >

            <Grid container id="testDownload">
                    <Grid item xs><div onClick={() => onCatClick("Total", "6188bd83622ce30035b79da8")}
                        className={classes.root} id="6188bb7e622ce30035b79d63"></div></Grid>
                    <Grid item xs><div onClick={() => onCatClick("Backlog", "6188bd0d622ce30035b79d8b")}
                        className={classes.root} id="6188bb7e622ce30035b79d64"></div></Grid>
                    <Grid item xs><div onClick={() => onCatClick("Reopened", "6188bdaf622ce30035b79db6")}
                        className={classes.root} id="6188bb7e622ce30035b79d66"></div></Grid>
                </Grid>

                <Grid container id="testDownload">
                    <Grid item xs><div className={classes.root + " noncursor"}
                        onClick={() => onCatClick("TurnAround", "6188bdb7622ce30035b79dc4")}
                        id="6188bb7e622ce30035b79d65"></div></Grid>
                    <Grid item xs><div onClick={() => onCatClick("Email", "6188bd05622ce30035b79d7d")}
                        className={classes.root} id="6188bb7e622ce30035b79d67"></div></Grid>
                    <Grid item xs><div onClick={() => onCatClick("Cancelled", "6188bd15622ce30035b79d99")}
                        className={classes.root} id="6188bb7e622ce30035b79d62"></div></Grid>
                </Grid>
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      app: state.appReducer,
    };
  };

export default connect(mapStateToProps)(CssDashboard)
