export const buttonFilters = [
    {
        name:"Deployment Type",
        data:[
            {
                label:"Cash",
                value:"Cash"
            },
            {
                label:"Non-Cash",
                value:"Non-Cash"
            },
            {
                label:"O&G Cash",
                value:"O&G Cash"
            },
        ]
    
},
    {
        name:"Output Variance",
        data:[
            
            {
                label:"FT Management Diversity",
                value:"FT Management Diversity"
            },
            {
                label:"Overall Attrition Rate",
                value:"Overall Attrition Rate"
            },
            {
                label:"Reduction in Time to fill",
                value:"Reduction in Time to fill"
            }
        ]
    }];