import React from "react";

const override = `
position: absolute;
top: 40%;
left: 50%;
z-index: 1000;
`;
//define months array
let months = new Array();
months[0] = "January";
months[1] = "February";
months[2] = "March";
months[3] = "April";
months[4] = "May";
months[5] = "June";
months[6] = "July";
months[7] = "August";
months[8] = "September";
months[9] = "October";
months[10] = "November";
months[11] = "December";

class DateTimeClock extends React.Component {
    constructor(props, context) {
        super(props, context);
    this.state = {
  
        time: new Date(),
      
    };
}
    componentDidMount() {
        setInterval(() => {
            this.setState({ time: new Date() });
        }, 1 * 1000); // every 1 seconds



    }
  
 
    
    render() {
        var options = { year: 'numeric', month: 'long', day: 'numeric', hour12: true, minutes: 'numeric', seconds: 'numeric' };

        //Construct dropdown options for filters
     
        return (
            <div className="col-sm-3 align-self-center pt-4">
            {this.props.langCode.toLowerCase() == 'en'? 
                <p className="f-14">{months[this.state.time.getMonth()] + " " + this.state.time.getDate() + ", " + this.state.time.getFullYear() + " " + this.state.time.toLocaleTimeString()}</p>:
                <p className="f-14">{this.state.time.toLocaleTimeString(this.props.langCode, options)}</p> }
        </div>
        );
    }
}


export default DateTimeClock
