import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { otherDash } from "../../config/SisenseDashboards";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark + " !important",
        border: "5px solid #0058ff",
        borderRadius: "12px"
    },
    main: {
    },
    modal: {
        backgroundColor: theme.palette.primary.main,
        padding: "16px",
        margin: '40px auto',
        borderRadius: '4px',
        color: theme.palette.text.main,
        width: "75%"
    },

}));

const OtherDash = (props) => {
    const classes = useStyles();
    const app = props.currentApp;
    const [dash, setDash] = React.useState(null);
    const [openModal, setopenModal] = React.useState(false);

    /**
     * function to display business outcome widgets
     * @param {*} dashId input type should be a valid dashboard ID as string
     */
    const ApplyFilter = (db) => {
        const filterOptions = {
            save: true,
            refresh: true,
            unionIfSameDimensionAndSameType: false
        };
        db.$$model.filters.update(props.payload, filterOptions);
    }
    useEffect(() => {
        reLoadDash();
    });
    const reLoadDash = () => {
        if (dash) {
            ApplyFilter(dash);
        }
    };
    const loadDash = (dashId) => {
        if (app && app.dashboards) {
            const wgts = otherDash.filter(f => f.dashboardId === dashId)[0].widgets;
            app.dashboards.load(dashId).then(data => {
                setDash(data);
                ApplyFilter(data);
                wgts.forEach(wgt => {
                    const oWgt = data.widgets.get(wgt.widgetId)
                    const div = document.getElementById(wgt.widgetId);
                    if (div) div.innerHTML = "";
                    oWgt.container = div;
                    oWgt.refresh();
                })
            });
        }
    }
    useEffect(() => {
        let widgetId = "5fbe15bdc36cf10b3c3c4845";
        loadDash('61bc56f5e404220036c7187c');
        if (app && app.dashboards) {
            app.dashboards.load("5fbe15bdc36cf10b3c3c483d").then(data => {
                const oWgt = data.widgets.get(widgetId);
                const div = document.getElementById(widgetId);
                div.innerHTML = "";
                oWgt.container = div;
                oWgt.refresh();
            });
        }
    }, [])

    const portfolioFilter = (db, catPayload) => {
        const filterOptions = {
            save: true,
            refresh: true,
            unionIfSameDimensionAndSameType: false
        };
        db.$$model.filters.update(catPayload, filterOptions);
    }
    const onCatClick = (cat) => {
        setopenModal(true);
        const widgetId = "61c2e6d655a5390036c6c586";

            let clients;
            if (props.appData.filterObject.tenant.length > 0) {
              clients = [...props.appData.filterObject.tenant];
            } else {
              let tenants = [];
              props.userDetails.tenant.map((item) => {
                tenants.push(item.pseudoName);
              });
              clients = [...tenants];
            }
            let clientsFilterObj;
            if (clients.length > 0) {
              clientsFilterObj = {
                explicit: true,
                multiSelection: true,
                members: clients.map((a) => a.toUpperCase()),
              }
            } else {
              clientsFilterObj = {
                all: true,
              }
            }

        var filterCat;
        if (cat === "others") {
            filterCat = {
                "and": [
                    {
                        "doesntContain": "Application/Software"
                    },
                    {
                        "doesntContain": "Network"
                    }
                ]
            }
        }
        else {
            filterCat = {
                explicit: true,
                members: [cat]
            }
        }
        var payload = [{
            levels: [
                {
                    dim: "[Service Tickets.Type]",
                    filter: {
                        explicit: true,
                        members: ["Pseudo"],
                        multiSelection: false,
                    }
                },
                {
                    dim: "[Service Tickets.Client]",
                    filter: clientsFilterObj
                }
            ],
        },
        {
            jaql: {
                dim: "[Service Tickets.Category]",
                datatype: "text",
                filter: filterCat
            },
        }]

        if (app && app.dashboards) {
            app.dashboards.load("61c2e6d655a5390036c6c585").then(data => {
                portfolioFilter(data, payload);
                const oWgt = data.widgets.get(widgetId);
                const div = document.getElementById(widgetId);
                div.innerHTML = "";
                oWgt.container = div;
                oWgt.refresh();
            });
        }
    }
    return (

        <React.Fragment>
            <Modal show={openModal} centered onHide={() => setopenModal(false)} dialogClassName="modal-90w">
            <ModalHeader style={{borderBottom:"none"}} closeButton={true}/>
            <Modal.Body><div id="61c2e6d655a5390036c6c586" className="db-container"></div>
            </Modal.Body>
            </Modal>

            <div className={classes.main + " indicatorMain"} style={{ zoom: "0.5" }}>
                <Grid container>
                    <Grid item xs><div onClick={() => onCatClick("Application/Software")}
                        className={classes.root + " indicators"} id="61bc5af3e404220036c718e4"></div></Grid>
                    <Grid item xs><div onClick={() => onCatClick("Network Outage")}
                        className={classes.root + " indicators"} id="61bc5b75e404220036c718fc"></div></Grid>
                    <Grid item xs><div onClick={() => onCatClick("others")}
                        className={classes.root + " indicators"} id="61bc5d98e404220036c719d2"></div></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs><div className={classes.root + " openInc"} id="61bc5aeae404220036c718e1"></div></Grid>
                    <Grid item xs><div className={classes.root + " openInc"} id="61bc5b89e404220036c71907"></div></Grid>
                    <Grid item xs><div className={classes.root + " openInc"} id="61bc5d9be404220036c719d4"></div></Grid>
                </Grid>
                <div className=" mt-20" id="61c42cdd55a5390036c6d7b9" style={{ zoom: "1", float:"right" }} />
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      appData: state.appReducer,
    };
  };

export default connect(mapStateToProps)(OtherDash)
