import React, { useEffect } from "react";
import { otherDashTickets } from "../../config/SisenseDashboards";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark + " !important",
        // border: "5px solid #0058ff",
        borderRadius: "12px",
        minHeight: "100px"
    },
    main: {
        padding: "20px",
        paddingTop:"0px"
    }

}));

const OtherDashTickets = (props) => {
    const classes = useStyles();
    const app = props.currentApp;
    const [dash, setDash] = React.useState(null);

    const ApplyFilter = (db) => {
        const filterOptions = {
            save: true,
            refresh: true,
            unionIfSameDimensionAndSameType: false
        };
        db.$$model.filters.update(props.payload, filterOptions);
    }
    useEffect(() => {
        reLoadDash();
    });
    const reLoadDash = () => {
        if (dash) {
            ApplyFilter(dash);
        }
    };
    const loadDash = (dashId) => {
        if (app && app.dashboards) {
            const wgts = otherDashTickets.filter(f => f.dashboardId === dashId)[0].widgets;
            app.dashboards.load(dashId).then(data => {
                setDash(data);
                ApplyFilter(data);
                wgts.forEach(wgt => {
                    const oWgt = data.widgets.get(wgt.widgetId)
                    const div = document.getElementById(wgt.widgetId);
                    if (div) div.innerHTML = "";
                    oWgt.container = div;
                    oWgt.refresh();
                })
            });
        }
    }

    useEffect(() => {
        loadDash('61bc56f5e404220036c7187c');

    }, [])

    return (
        <div className={classes.main} style={{ zoom: "0.7" }}>
            <div className="disp-flex">
                <div className=" indicators1" id="61bc574ce404220036c71894" style={{ minHeight: "40px" }}></div>
                <div className=" indicators1" id="61bc5751e404220036c71896" style={{ minHeight: "40px" }}></div>
            </div>
            <div className="disp-flex">
                <div className={classes.root + " openInc1"} id="61bc59b0e404220036c718b7" style={{ height: "112px" }}></div>
                <div className={classes.root + " openInc1"} id="61c1b94f55a5390036c6b008" style={{ height: "112px" }}></div>
            </div>
        </div>

    );
}

export default OtherDashTickets;
