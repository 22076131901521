import React from "react";
import { connect } from "react-redux";
import Logo from "../src/Images/Connecting.PNG";
import "./App.css";


class Connecting extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {}
    }
    render() {
        const root = {
            backgroundColor: "#022070",
            padding: "16px",
            color: "#FFF",
            height: "100vh",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }
    return (
        
        <header>
          <div style={root}>
            <div>
              <img
                src={Logo}
                alt="logo"
                className="rotate"
                style={{ width: "137px" }}
              />
              <div className="ml-10 ">Connecting...</div>
            </div>
          </div>
        </header>
    )
    }
}

const mapStateToProps = (state) => {
    return {
      app: state.appReducer,
  
    };
  };
const mapDispatchToProps = (dispatch) => {
    return {
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(Connecting);