const appReducer = (
  state = {
    showMenu:true,
    clientOptions:[],
    filterObject: {
      offering: [],
      market: [],
      deliveryLocation: [],
      assetType: [],
      tenant: [],
      tenantId:[]
  },
  clients:[],
  languageCode:'',
  language:'',
  languageTranslationArray:{},
  demoClient:false
  },
  action
) => {
  switch (action.type) {
    case "SHOW_MENU":
      state = {
        ...state,
        showMenu: action.payload,
      };
      break;
    case "SET_FILTER":
      state = {
        ...state,
        filterObject: action.payload,
      };
      break;
      case 'SET_CLIENTS':
        state = {
          ...state,
          clients: action.payload,
        };
        break;
        case 'SET_CLIENT_OPTIONS':
          state = {
            ...state,
            clientOptions: action.payload,
          };
          break;
        case "SET_LANGUAGE_CODE":
        state = {
          ...state,
          languageCode: action.payload,
          language:action.language
        };
        break;
        case 'SET_LANGUAGE_TRANSLATIONS':
        state = {
          ...state,
          languageTranslationArray: action.payload,
        };
        break;
       case "SET_DEMO_CLIENT":
        state = {
          ...state,
          demoClient: action.payload,
        };
        break;
  }
  return state;
};

export default appReducer;
